import React from 'react';
import {Box, Heading, IconButton, SimpleGrid} from '@chakra-ui/react';

import {UserDetails} from '../UserDetails';
import {useRecoilValue} from 'recoil';
import {activeUsers} from '../../selectors';
import {useTranslation} from 'react-i18next';
import {EditIcon} from '@chakra-ui/icons';
import {useUsersEditor} from '../../hooks';

const UsersDetails: React.FC = () => {
    const users = useRecoilValue(activeUsers);
    const [t] = useTranslation('common');
    const {open} = useUsersEditor();

    return (
        <Box>
            <Heading as="h2" fontWeight="bold" marginBottom="5">
                {t('orderPage.usersHeading')}

                <IconButton
                    ml="5px"
                    size="sm"
                    colorScheme="purple"
                    variant="ghost"
                    aria-label="edit users"
                    icon={<EditIcon boxSize="5" />}
                    onClick={open}
                />
            </Heading>

            <SimpleGrid columns={[1, 2, 3]} spacing="3">
                {users.map(user => (
                    <UserDetails key={user.id} {...user} />
                ))}
            </SimpleGrid>
        </Box>
    );
};

export default UsersDetails;

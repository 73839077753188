import {atom} from 'recoil';
import {persistAppStorageAtomEffect} from '../effects';

export interface TipsInfo {
    type: 'percent' | 'absolute';
    value: number | null;
}

export const currentTipsInfoDefault: TipsInfo = {
    type: 'absolute',
    value: null,
};

export const currentTipsInfo = atom<TipsInfo>({
    key: 'TipsInfo',
    default: currentTipsInfoDefault,
    effects: [persistAppStorageAtomEffect('currentTipsInfo')],
});

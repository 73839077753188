import {selector} from 'recoil';
import {destributedOrderItems} from '../currentOrderItems';

/**
 * [Reference Currency]
 * Получение значения общего счета без налогов и чаевых и скидок.
 */
export const currentReferenceCurrencyRawAmount = selector({
    key: 'CurrentReferenceCurrencyRawAmount',
    get: ({get}) => {
        const orderItems = get(destributedOrderItems);

        let currentAmount = 0;

        orderItems.forEach(({value = 0}) => {
            currentAmount += value;
        });

        return currentAmount;
    },
});

import {atom} from 'recoil';
import {persistAppStorageAtomEffect} from '../effects';

export const currentOrderNameDefault = null;

export const currentOrderName = atom<null | string>({
    key: 'OrderName',
    default: currentOrderNameDefault,
    effects: [persistAppStorageAtomEffect('orderName')],
});

import React from 'react';
import {useTranslation} from 'react-i18next';
import {Box, Stack, useRadio, useRadioGroup} from '@chakra-ui/react';
import {useRecoilState} from 'recoil';
import {currentRateCalculationType} from '../../state';

const RadioCard: React.FC<any> = props => {
    const {getInputProps, getCheckboxProps} = useRadio(props);

    const input = getInputProps();
    const checkbox = getCheckboxProps();

    return (
        <Box as="label">
            <input {...input} />
            <Box
                {...checkbox}
                cursor="pointer"
                borderWidth="1px"
                borderRadius="md"
                boxShadow="md"
                _checked={{
                    bg: 'purple.500',
                    color: 'white',
                    borderColor: 'purple.500',
                }}
                px={5}
                py={2}
            >
                {props.children}
            </Box>
        </Box>
    );
};

export const CurrencyRateSwitcher: React.FC<any> = () => {
    const [t] = useTranslation('common');
    const options = [t('Calculate exchange rate'), t('I know exchnage rate')];
    const [rateCalculationType, setRateCaculationType] = useRecoilState(currentRateCalculationType);

    const {getRootProps, getRadioProps} = useRadioGroup({
        value: rateCalculationType === 'fromAmount' ? t('Calculate exchange rate') : t('I know exchnage rate'),
        name: 'rateSetType',
        onChange: value => {
            if (value === t('Calculate exchange rate')) {
                setRateCaculationType('fromAmount');
            } else {
                setRateCaculationType('manual');
            }
        },
    });

    const group = getRootProps();

    return (
        <Stack direction={['column', null, null, 'row']} {...group}>
            {options.map(value => {
                const radio = getRadioProps({value});

                return (
                    <RadioCard key={value} {...radio}>
                        {value}
                    </RadioCard>
                );
            })}
        </Stack>
    );
};

import React from 'react';

import {Avatar, Button, HStack, Text} from '@chakra-ui/react';
import {Card} from '../Card';
import styles from './Header.module.css';
import {Logo} from '../Logo';
import {LanguageSwitcher} from '../LanguageSwitcher';
import {useRecoilValue} from 'recoil';
import {useTranslation} from 'react-i18next';
import {currentLoggedUserQuery} from '../../queries';

interface Props {
    withLogin?: boolean;
}

const Header: React.FC<Props> = ({withLogin = true}) => {
    const loggedUser = useRecoilValue(currentLoggedUserQuery);
    const [t] = useTranslation('common');

    return (
        <Card className={styles.root}>
            <HStack direction={['row', 'column']} justifyContent="space-between" w="full">
                <HStack as="a" href="/" alignItems="center">
                    <Logo />

                    <Text fontWeight="bold" fontSize="3xl">
                        Recklify
                    </Text>
                </HStack>

                <HStack>
                    <LanguageSwitcher />

                    {withLogin && loggedUser !== null && <Avatar src={loggedUser.avatarUrl} name={loggedUser.name} />}

                    {withLogin && loggedUser == null && (
                        <Button as="a" href="/auth" variant={'ghost'}>
                            {t('header.login')}
                        </Button>
                    )}
                </HStack>
            </HStack>
        </Card>
    );
};

export default Header;

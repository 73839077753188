import {atom} from 'recoil';
import {persistAppStorageAtomEffect} from '../effects';
import {OrderId} from '../types';

export const currentOrderIdDefault = null;

export const currentOrderId = atom<OrderId | null>({
    key: 'OrderId',
    default: currentOrderIdDefault,
    effects: [persistAppStorageAtomEffect('currentOrderId')],
});

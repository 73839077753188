import {fetchWithToken} from '../helpers';

export const deleteOrderById = async (id: string): Promise<void> => {
    const response = await fetchWithToken(`/orders/${id}`, {
        method: 'delete',
    });

    if (!response.ok) {
        throw Error('Order not deleted');
    }
};

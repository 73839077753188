import {StyleProps} from '@chakra-ui/react';

export const listItem: StyleProps = {
    minHeight: ['70px', '80px'],
    cursor: 'pointer',
};

export const content: StyleProps = {
    paddingY: '4',
    display: 'flex',
    justifyContent: 'space-between',
};

export const name: StyleProps = {
    marginTop: '-4px',
    fontSize: 'md',
    fontWeight: 'bold',
    marginBottom: '-4px',
};

export const id: StyleProps = {
    fontSize: 'x-small',
    color: 'grey',
};

export const amount: StyleProps = {
    whiteSpace: 'nowrap',
    fontSize: 'md',
    fontWeight: 'bold',
};

export const orderList: StyleProps = {
    pt: '15px',
};

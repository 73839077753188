import {atom} from 'recoil';
import {persistAppStorageAtomEffect} from '../effects';
import {OrderItem} from '../types';

export const currentOrderItemsDefault: OrderItem[] = [];

export const currentOrderItems = atom<OrderItem[]>({
    key: 'OrderItems',
    default: currentOrderItemsDefault,
    effects: [persistAppStorageAtomEffect('currentOrderItems')],
});

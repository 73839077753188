import React, {ReactEventHandler} from 'react';
import {Input, InputGroup, InputLeftElement, InputProps} from '@chakra-ui/react';
import {Currency} from '../../types';

interface Props extends InputProps {
    currency: Currency;
    onChange?: ReactEventHandler<HTMLInputElement>;
}

const currencyMap = {
    RUB: '₽',
    AMD: '֏',
};

const getCurrencyText = (currency: Currency): string => currencyMap[currency];

export const CurrencyInput = ({value, currency, placeholder, onChange, ...restProps}: Props): JSX.Element => (
    <InputGroup>
        <InputLeftElement pointerEvents="none" color="gray.300" fontSize="1.2em">
            {getCurrencyText(currency)}
        </InputLeftElement>

        <Input
            type="number"
            placeholder={placeholder}
            value={value ?? ''}
            onChange={onChange}
            {...restProps}
            autoComplete="off"
        />
    </InputGroup>
);

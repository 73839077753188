export const parseCookie = (str: string): Record<string, string> => {
    if (str.length === 0) {
        return {};
    }

    const cookies = str.split(';').map(cookie => cookie.split('='));

    return cookies.reduce<Record<string, string>>((acc, v) => {
        acc[decodeURIComponent(v[0].trim())] = decodeURIComponent(v[1].trim());

        return acc;
    }, {});
};

import React, {ReactEventHandler} from 'react';
import {Input, InputGroup, Select, InputRightAddon, FormControl, FormLabel} from '@chakra-ui/react';

interface Props {
    value: number | null;
    type?: 'absolute' | 'percent';
    title: string;
    placeholder: string;
    withoutTypeSelect?: boolean;
    onChangeType?: ReactEventHandler;
    onChangeInput?: ReactEventHandler;
}

export const CommonInput: React.FC<Props> = ({
    value,
    type,
    title,
    placeholder,
    withoutTypeSelect = false,
    onChangeType,
    onChangeInput,
}) => {
    return (
        <FormControl>
            <FormLabel fontWeight="bold">{title}</FormLabel>

            <InputGroup>
                <Input
                    type="number"
                    value={value === null ? '' : value}
                    autoComplete="off"
                    placeholder={placeholder}
                    onChange={onChangeInput}
                />

                {!withoutTypeSelect && (
                    <InputRightAddon paddingRight={0}>
                        <Select value={type} variant="unstyled" onChange={onChangeType}>
                            <option value="percent">%</option>
                            <option value="absolute">֏</option>
                        </Select>
                    </InputRightAddon>
                )}
            </InputGroup>
        </FormControl>
    );
};

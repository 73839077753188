import React from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Image, VStack, SimpleGrid, Heading, Text} from '@chakra-ui/react';
import {AddIcon} from '@chakra-ui/icons';

import {useUsersEditor} from '../../hooks';
import AddUserImage from './AddUser.svg';

const AddUser: React.FC = () => {
    const [t] = useTranslation('common');
    const {open} = useUsersEditor();

    return (
        <SimpleGrid
            columns={[1, null, 2]}
            alignItems="center"
            justifyContent="center"
            height={['auto', 'calc(100vh - 196px)']}
            spacing={[0, null, 20]}
        >
            <Image src={AddUserImage} boxSize={['xs', null, null, 'lg']} justifySelf={['center', null, 'end']} />
            <VStack
                justifySelf={['center', null, 'start']}
                maxWidth="lg"
                w="full"
                alignItems="flex-start"
                spacing="6"
                textAlign={['center', null, 'left']}
            >
                <Heading as="h2" fontSize={['3xl', null, null, '5xl']} w="full">
                    {t('onboarding.addUser.heading')}
                </Heading>
                <Text fontSize={['md', null, null, 'xl']} color="gray">
                    {t('onboarding.addUser.text')}
                </Text>
                <Button
                    colorScheme="purple"
                    leftIcon={<AddIcon />}
                    size={['md', null, null, 'lg']}
                    w="full"
                    onClick={() => open()}
                >
                    {t('onboarding.addUser.button')}
                </Button>
            </VStack>
        </SimpleGrid>
    );
};

export default AddUser;

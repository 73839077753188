import {selector} from 'recoil';
import {currentDiscountInfo} from '../../state';
import {calcDiscount} from '../../utils';
import {currentReferenceCurrencyRawAmount} from './currentReferenceCurrencyRawAmount';

/**
 * [Reference Currency]
 * Получение значения скидки.
 */
export const currentReferenceCurrencyDiscount = selector({
    key: 'CurrentReferenceCurrencyDiscount',
    get: ({get}) => {
        const rawAmount = get(currentReferenceCurrencyRawAmount);
        const discountInfo = get(currentDiscountInfo);

        return calcDiscount(rawAmount, rawAmount, discountInfo);

        // if (discountInfo.value !== null) {
        //     if (discountInfo.type === 'absolute') {
        //         return discountInfo.value;
        //     }
        //
        //     return rawAmount * (discountInfo.value / 100);
        // }
        //
        // return 0;
    },
});

import React, {useCallback, useState} from 'react';
import {
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    SimpleGrid,
    Text,
} from '@chakra-ui/react';

import {UserCheckbox} from '../UserCheckbox';
import {useRecoilValue} from 'recoil';
import {currentLoggedUserQuery} from '../../queries';
import {EditContactsList} from './EditContactsList';
import {currentUserContacts} from '../../state';
import {useTranslation} from 'react-i18next';
import {useUsersEditor} from '../../hooks';

const UserEditor: React.FC = () => {
    const [t] = useTranslation('common');
    const users = useRecoilValue(currentUserContacts);
    const currentUser = useRecoilValue(currentLoggedUserQuery);
    const [isEditMode, setEditMode] = useState(false);
    const {isOpen, close} = useUsersEditor();

    const onClickEditButton = useCallback(() => {
        setEditMode(true);
    }, []);

    const onClickOffEditButton = useCallback(() => {
        setEditMode(false);
    }, []);

    return (
        <Drawer isOpen={isOpen} onClose={close} placement="bottom" size="lg">
            <DrawerOverlay />

            <DrawerContent>
                <DrawerCloseButton />

                <DrawerHeader>
                    <Text fontSize="2xl" fontWeight="bold">
                        {isEditMode ? t('editContacts.contactList') : t('editContacts.selectParticipants')}
                    </Text>

                    {!isEditMode && (
                        <Button variant="link" fontWeight="normal" onClick={onClickEditButton}>
                            {t('editContacts.changeContactList')}
                        </Button>
                    )}
                </DrawerHeader>

                <DrawerBody>
                    {isEditMode && <EditContactsList users={users} onSubmit={onClickOffEditButton} />}

                    {!isEditMode && (
                        <SimpleGrid minChildWidth="300px" spacing="4">
                            {currentUser !== null && <UserCheckbox user={currentUser} />}

                            {users.map(user => {
                                return <UserCheckbox key={user.id} user={user} />;
                            })}
                        </SimpleGrid>
                    )}
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};

export default UserEditor;

import {Order} from '../../types';
import {assertIsDefined} from '../../utils';
import {JSON_HEADERS} from '../common';
import {fetchWithToken, prepareOrderRequestData} from '../helpers';

export const updateOrderById = async (order: Partial<Order>): Promise<Order | null> => {
    assertIsDefined(order.id);

    const response = await fetchWithToken(`/orders/${order.id}`, {
        method: 'put',
        headers: JSON_HEADERS,
        body: JSON.stringify(prepareOrderRequestData(order)),
    });

    if (response.ok) {
        return await response.json();
    }

    return null;
};

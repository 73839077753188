import {selector} from 'recoil';
import {calcTipsPerUsers} from '../../utils';
import {currentReferenceCurrencyTips} from './currentReferenceCurrencyTips';
import {activeUsersIdWithOrders} from '../activeUsersIdWithOrder';

/**
 * [Reference Currency]
 * Получение значения чаевых на пользователя.
 */
export const currentReferenceCurrencyTipsPerUser = selector({
    key: 'CurrentReferenceCurrencyTipsPerUser',
    get: ({get}) => {
        const tips = get(currentReferenceCurrencyTips);
        const users = get(activeUsersIdWithOrders);

        return calcTipsPerUsers(tips, users.length);
    },
});

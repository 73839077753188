import {useRecoilState} from 'recoil';

import {usersEditor} from '../state/usersEditor';

interface IUseUsersEditor {
    isOpen: boolean;
    open: () => void;
    close: () => void;
}

const useUsersEditor = (): IUseUsersEditor => {
    const [usersEditorState, setUserEditorState] = useRecoilState(usersEditor);
    const {isOpen} = usersEditorState;

    const open = (): void => {
        setUserEditorState({isOpen: true});
    };

    const close = (): void => {
        setUserEditorState({isOpen: false});
    };

    return {isOpen, open, close};
};

export default useUsersEditor;

import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {Box, Button, Heading, Image, SimpleGrid, Text, VStack} from '@chakra-ui/react';
import emptyImage from '../../images/emptyImage.svg';
import * as styles from './style';

interface Props {
    className?: string;
}

export const EmptyOrdersListContent: FC<Props> = ({className}) => {
    const [t] = useTranslation('common');

    return (
        <Box className={className}>
            <SimpleGrid {...styles.gridLayout}>
                <Image src={emptyImage} {...styles.image} />

                <VStack {...styles.descriptionBlock}>
                    <Heading as="h2" {...styles.title}>
                        {t('ordersPage.title')}
                    </Heading>

                    <Text {...styles.text}>{t('ordersPage.description')}</Text>

                    <Button size="lg">{t('ordersPage.addButton')}</Button>
                </VStack>
            </SimpleGrid>
        </Box>
    );
};

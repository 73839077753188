import React from 'react';
import {Icon, IconProps} from '@chakra-ui/react';

const RussiaIcon: React.FC<IconProps> = props => {
    return (
        <Icon viewBox="0 0 9 6" {...props}>
            <rect fill="#fff" width="9" height="3" />
            <rect fill="#d52b1e" y="3" width="9" height="3" />
            <rect fill="#0039a6" y="2" width="9" height="2" />
        </Icon>
    );
};

export default RussiaIcon;

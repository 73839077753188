import {selector} from 'recoil';
import {currentOrderItems as currentOrderItemsState} from '../state';
import {activeUsers} from './activeUsers';

/**
 * Получения списка позиций распределенных на пользователей.
 */
export const destributedOrderItems = selector({
    key: 'DestributedOrderItems',
    get: ({get}) => {
        const orderItems = get(currentOrderItemsState);
        const users = get(activeUsers);

        return orderItems
            .filter(orderItem => orderItem.users.length > 0)
            .map(orderItem => {
                return {
                    ...orderItem,
                    users: orderItem.users.filter(userId => users.some(user => user.id === userId)),
                };
            });
    },
});

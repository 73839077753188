import {selector} from 'recoil';
import {BillInfo} from '../../types';
import {currentReferenceCurrencyBillByUser} from '../referenceCurrency';
import {currentExchangeRate} from '../exchangeRate';

type BillByUser = Record<string, BillInfo>;

/**
 * [Target Currency]
 * Получения мапы с счетами разделенными по пользователям в рублях.
 */
export const currentTargetCurrencyBillByUser = selector({
    key: 'CurrentTargetCurrencyBillByUser',
    get: ({get}) => {
        const billByUser = get(currentReferenceCurrencyBillByUser);
        const exchangeRate = get(currentExchangeRate);

        const billInRubles: BillByUser = {};

        Object.keys(billByUser).forEach(userId => {
            const {value, total, tax, tips, discount, delivery} = billByUser[userId];

            billInRubles[userId] = {
                value: value * exchangeRate,
                discount: discount * exchangeRate,
                total: total * exchangeRate,
                tax: tax * exchangeRate,
                delivery: delivery * exchangeRate,
                tips: tips * exchangeRate,
            };
        });

        return billInRubles;
    },
});

import {selector} from 'recoil';
import {currentReferenceCurrencyBillInfo} from '../referenceCurrency';
import {currentExchangeRate} from '../exchangeRate';

/**
 * [Target Currency]
 * Получение значения общего счета с включенными налогами.
 */
export const currentTargetCurrencyBillInfo = selector({
    key: 'CurrentTargetCurrencyBillInfo',
    get: ({get}) => {
        const referenceBillInfo = get(currentReferenceCurrencyBillInfo);
        const exchangeRate = get(currentExchangeRate);

        return {
            tips: referenceBillInfo.tips * exchangeRate,
            tax: referenceBillInfo.tax * exchangeRate,
            discount: referenceBillInfo.discount * exchangeRate,
            delivery: referenceBillInfo.delivery * exchangeRate,
            total: referenceBillInfo.total * exchangeRate,
        };
    },
});

import React, {ChangeEvent, useCallback} from 'react';
import {useTranslation} from 'react-i18next';

import {useRecoilState} from 'recoil';
import {currentDeliveryInfo} from '../../../state';
import {CommonInput} from '../CommonInput';

export const DeliveryInput: React.FC = () => {
    const [t] = useTranslation('common');

    const [deliveryInfo, setDeliveryInfo] = useRecoilState(currentDeliveryInfo);

    const onChangeDeliveryValue = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const delivery = value === '' ? null : Number(value);

        setDeliveryInfo({value: delivery});
    }, []);

    return (
        <CommonInput
            placeholder={t('corrections.delivery.inputPlaceholder')}
            value={deliveryInfo?.value}
            title={t('corrections.delivery.title')}
            onChangeInput={onChangeDeliveryValue}
            withoutTypeSelect
        />
    );
};

import {atom} from 'recoil';
import {persistAppStorageAtomEffect} from '../effects';

export interface DeliveryInfo {
    value: number | null;
}

export const currentDeliveryInfo = atom<DeliveryInfo>({
    key: 'DeliveryInfo',
    default: {
        value: null,
    },
    effects: [persistAppStorageAtomEffect('currentDeliveryInfo')],
});

import {User} from '../types';
import {generateId} from '../utils';

interface CreateUserParams {
    name: string;
}

export const createUser = ({name}: CreateUserParams): User => ({
    id: generateId(),
    name,
});

import React, {ChangeEvent, useCallback} from 'react';
import {useCheckbox, UseCheckboxProps, Box} from '@chakra-ui/react';

import {UserChecked} from '../UserChecked';
import {User} from '../../types';
import {useRecoilState} from 'recoil';
import {currentSelectedParticipants} from '../../state';

interface Props extends UseCheckboxProps {
    user: User;
}

const UserCheckbox: React.FC<Props> = ({user, ...props}) => {
    const [users, setUsers] = useRecoilState(currentSelectedParticipants);

    const isSelected = users.some(participant => participant.id === user.id);

    const onChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>): void => {
            if (event.target.checked) {
                setUsers([...users, user]);
            } else {
                setUsers(users.filter(participant => participant.id !== user.id));
            }
        },
        [users],
    );

    const {state, getInputProps, getCheckboxProps, getLabelProps} = useCheckbox({
        ...props,
        onChange,
        isChecked: isSelected,
    });

    return (
        <Box as="label" minW="300px" {...getLabelProps()}>
            <input {...getInputProps()} hidden />

            <UserChecked user={user} isChecked={state.isChecked} {...getCheckboxProps()} />
        </Box>
    );
};

export default UserCheckbox;

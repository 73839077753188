import {atom} from 'recoil';
import {persistAppStorageAtomEffect} from '../effects';
import {RateCalculationType} from '../types/rateCalculationType';

/**
 * Тип рассчета курса обмена.
 */
export const currentRateCalculationType = atom<RateCalculationType>({
    key: 'RateCalculationType',
    default: 'fromAmount',
    effects: [persistAppStorageAtomEffect('currentRateCalculationType')],
});

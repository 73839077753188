import {useSetRecoilState} from 'recoil';
import {
    currentDeliveryInfo,
    currentDiscountInfo,
    currentManualExchangeRate,
    currentOrderId,
    currentOrderItems,
    currentOrderName,
    currentRateCalculationType,
    currentTargetCurrencyAmount,
    currentTaxInfo,
    currentTipsInfo,
    currentSelectedParticipants,
    currentUserContacts,
} from '../state';
import {Order} from '../types';

type AppDataUpdateFunction = (data: Partial<Order>) => void;

export const useSetAppData = (): AppDataUpdateFunction => {
    const setId = useSetRecoilState(currentOrderId);
    const setName = useSetRecoilState(currentOrderName);
    const setUserContacts = useSetRecoilState(currentUserContacts);
    const setSelectedParticipants = useSetRecoilState(currentSelectedParticipants);
    const setDiscountInfo = useSetRecoilState(currentDiscountInfo);
    const setTaxInfo = useSetRecoilState(currentTaxInfo);
    const setTipsInfo = useSetRecoilState(currentTipsInfo);
    const setTargetCurrencyAmount = useSetRecoilState(currentTargetCurrencyAmount);
    const setOrderItems = useSetRecoilState(currentOrderItems);
    const setRateCalculationType = useSetRecoilState(currentRateCalculationType);
    const setManualExchangeRate = useSetRecoilState(currentManualExchangeRate);
    const setDeliveryInfo = useSetRecoilState(currentDeliveryInfo);

    const settersMap: $TSFixME = {
        id: setId,
        name: setName,
        users: setSelectedParticipants,
        discountInfo: setDiscountInfo,
        taxInfo: setTaxInfo,
        tipsInfo: setTipsInfo,
        deliveryInfo: setDeliveryInfo,
        targetCurrencyAmount: setTargetCurrencyAmount,
        orderItems: setOrderItems,
        manualExchangeRate: setManualExchangeRate,
        rateCalculationType: setRateCalculationType,
        contacts: setUserContacts,
    };

    return (appData: $TSFixME) => {
        Object.keys(appData).forEach(key => {
            if (appData[key] !== undefined && settersMap[key] !== undefined) {
                settersMap[key](appData[key]);
            }
        });
    };
};

import React, {ChangeEvent, ReactEventHandler, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {
    Box,
    IconButton,
    useDisclosure,
    FormLabel,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalHeader,
    ModalCloseButton,
    Button,
    ModalFooter,
    Input,
    useToast,
} from '@chakra-ui/react';
import {SaveIcon} from '../icons';
import {useRecoilCallback, useRecoilState} from 'recoil';
import {currentOrderName} from '../../state';
import {currentAppData} from '../../selectors';
import {createOrder, updateOrderById} from '../../resolvers';

interface SaveFormProps {
    onClose: ReactEventHandler;
}

const SaveForm: React.FC<SaveFormProps> = ({onClose}) => {
    const [t] = useTranslation('common');
    const toast = useToast();

    const [orderName, setOrderName] = useRecoilState(currentOrderName);

    const onChangeName = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;

        setOrderName(value);
    }, []);

    const onSave = useRecoilCallback(
        ({snapshot}): ReactEventHandler =>
            async event => {
                const appOrder = await snapshot.getPromise(currentAppData);

                const isNewOrder = appOrder.id === null;

                let response;

                try {
                    if (isNewOrder) {
                        response = await createOrder(appOrder);
                    } else {
                        response = await updateOrderById(appOrder);
                    }

                    if (response === null) {
                        toast({
                            /**
                             * @todo [MoonW1nd]: add i18n
                             */
                            title: 'Для того чтобы пошарить ссылку, авторизуйтесь!',
                            position: 'top-right',
                            status: 'error',
                            duration: 2000,
                        });
                    } else {
                        toast({
                            title: 'Order is saved 😎',
                            position: 'top-right',
                            status: 'success',
                            duration: 2000,
                        });
                    }
                } catch (e) {
                    console.error(e);
                } finally {
                    onClose(event);
                }
            },
        [],
    );

    return (
        <ModalContent>
            <ModalCloseButton />

            <ModalHeader>{t('saveAction.form.title')}</ModalHeader>

            <ModalBody>
                <FormLabel fontWeight="bold">{t('saveAction.form.inputLabel')}</FormLabel>

                <Input value={orderName ?? ''} onChange={onChangeName} />
            </ModalBody>

            <ModalFooter>
                <Button colorScheme="purple" mr={3} onClick={onSave}>
                    {t('Save')}
                </Button>

                <Button colorScheme="gray" mr={3} onClick={onClose}>
                    {t('Close')}
                </Button>
            </ModalFooter>
        </ModalContent>
    );
};

export const SaveAction: React.FC = () => {
    const {isOpen, onOpen, onClose} = useDisclosure();

    return (
        <Box>
            <IconButton
                colorScheme="purple"
                aria-label="save"
                icon={<SaveIcon width="20px" height="20px" />}
                onClick={onOpen}
            />

            <Modal size="xl" isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />

                <SaveForm onClose={onClose} />
            </Modal>
        </Box>
    );
};

import {useResetRecoilState, useSetRecoilState} from 'recoil';

import {
    currentDiscountInfo as currentDiscountInfoState,
    currentManualExchangeRate,
    currentOrderItems as currentOrderItemsState,
    currentTargetCurrencyAmount as currentTargetCurrencyAmountState,
    currentTaxInfo as currentTaxInfoState,
    currentTipsInfo as currentTipsInfoState,
    currentOrderId as currentOrderIdState,
    currentOrderName as currentOrderNameState,
} from '../state';
import {getDefaultOrderName} from '../utils';

const useReset = (): (() => void) => {
    const setOrderName = useSetRecoilState(currentOrderNameState);

    const resetCurrentOrderIdState = useResetRecoilState(currentOrderIdState);
    const resetCurrentOrderNameState = (): void => setOrderName(getDefaultOrderName());
    const resetCurrentDiscountInfoState = useResetRecoilState(currentDiscountInfoState);
    const resetCurrentOrderItemsState = useResetRecoilState(currentOrderItemsState);
    const resetCurrentTargetCurrencyAmountState = useResetRecoilState(currentTargetCurrencyAmountState);
    const resetCurrentTaxInfoState = useResetRecoilState(currentTaxInfoState);
    const resetCurrentTipsInfoState = useResetRecoilState(currentTipsInfoState);
    const resetManualExchangeRate = useResetRecoilState(currentManualExchangeRate);

    const reset = (): void => {
        resetCurrentOrderIdState();
        resetCurrentOrderNameState();
        resetCurrentDiscountInfoState();
        resetCurrentOrderItemsState();
        resetCurrentTargetCurrencyAmountState();
        resetCurrentTaxInfoState();
        resetCurrentTipsInfoState();
        resetManualExchangeRate();
    };

    return reset;
};

export default useReset;

import {selector} from 'recoil';
import {mapAPIUserToUser} from '../entities';
import {resolveUserContacts} from '../resolvers';

/**
 * Получения списка контактов актовного пользователя.
 */
export const currentUserContactsQuery = selector({
    key: 'currentUserContactsQuery',
    get: async () => {
        const users = await resolveUserContacts();

        if (users === null) {
            return [];
        }

        return users.map(mapAPIUserToUser);
    },
});

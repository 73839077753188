import React from 'react';
import {useTranslation} from 'react-i18next';
import {Box, Heading, VStack} from '@chakra-ui/react';

import {DetalizationList} from '../DetalizationList';
import {Card} from '../Card';
import {useRecoilValue} from 'recoil';
import {currentOrderItems} from '../../state';

const Detalization: React.FC = () => {
    const [t] = useTranslation('common');
    const orderItems = useRecoilValue(currentOrderItems);

    return (
        <Box>
            <Heading as="h2" marginBottom={5}>
                {t('Detalization')}
            </Heading>

            <Card>
                <VStack spacing={4} align="stretch">
                    <DetalizationList items={orderItems} />
                </VStack>
            </Card>
        </Box>
    );
};

export default Detalization;

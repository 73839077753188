import {selector} from 'recoil';
import {currentSelectedParticipants} from '../state';

/**
 * Получения списка активных пользователей.
 */
export const activeUsers = selector({
    key: 'ActiveUsers',
    get: ({get}) => {
        const users = get(currentSelectedParticipants);

        return users.filter(user => Boolean(user.name));
    },
});

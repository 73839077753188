import {DiscountInfo} from '../state';

/**
 * @param amount - потрачено в чеке (часть чека)
 * @param totalAmount - полная стоимость чека
 * @param discountInfo - настройки скидки
 */
export const calcDiscount = (amount: number, totalAmount: number, discountInfo: DiscountInfo): number => {
    const value = discountInfo.value ?? 0;
    const type = discountInfo.type;

    if (type === 'percent') {
        return (amount * value) / 100;
    }

    if (amount === totalAmount) {
        return value;
    }

    return (amount / totalAmount) * value;
};

import {Button, Heading, Image, SimpleGrid, Text, VStack} from '@chakra-ui/react';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {YandexIcon} from '../../components/icons';
import {Page} from '../Page';
import loginImage from './loginImage.svg';

export const AuthPage: React.FC = () => {
    const [t] = useTranslation('common');

    return (
        <Page withLogin={false}>
            <SimpleGrid
                columns={[1, 2]}
                alignItems="center"
                justifyContent="center"
                height={['auto', 'calc(100vh - 196px)']}
                spacing={[0, 20]}
            >
                <Image src={loginImage} boxSize={['xs', 'lg']} justifySelf={['center', 'end']} />

                <VStack maxWidth="lg" w="full" spacing={6} display="flex" alignItems={['center', 'flex-start']}>
                    <Heading as="h2" fontSize="5xl">
                        {t('authPage.title')}
                    </Heading>

                    <Text fontSize="xl" color="gray" paddingX={['10px', '0']}>
                        {t('authPage.description')}
                    </Text>

                    <Button
                        as="a"
                        href="/api/auth/yandex"
                        backgroundColor="black"
                        display="flex"
                        width="280px"
                        padding="16px 54px"
                        height="56px"
                        borderRadius="12px"
                        _hover={{backgroundColor: 'black'}}
                        _active={{backgroundColor: 'black'}}
                        leftIcon={<YandexIcon width="24px" height="24px" />}
                    >
                        {t('authPage.yandex.button')}
                    </Button>
                </VStack>
            </SimpleGrid>
        </Page>
    );
};

import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {
    Text,
    HStack,
    Stack,
    Grid,
    Box,
    Flex,
    Heading,
    IconButton,
    useEditableControls,
    ButtonGroup,
    EditablePreview,
    EditableInput,
    Editable,
} from '@chakra-ui/react';

import {Card} from '../Card';
import {useRecoilState, useRecoilValue} from 'recoil';
import {currentTargetCurrencyBillInfo, currentReferenceCurrencyBillInfo} from '../../selectors';
import {CheckIcon, CloseIcon, EditIcon} from '@chakra-ui/icons';
import {currentOrderId, currentOrderName} from '../../state';
import {updateOrderById} from '../../resolvers';

const KeyValue: React.FC<{label: string; targetAmount: number; referenceAmount: number}> = props => {
    const {label, targetAmount, referenceAmount} = props;

    return (
        <HStack justifyContent="space-between" w="100%" flexGrow={1}>
            <Text fontWeight="normal" fontSize={['xl', 'md', null, null, 'xl']} color="GrayText">
                {label}
            </Text>

            <Stack alignItems="flex-end" spacing={0}>
                <Text fontWeight="bold" fontSize={['3xl', '2xl', null, null, '3xl']}>
                    {targetAmount.toFixed(2)} ₽
                </Text>

                <Text fontWeight="normal" color="GrayText" fontSize={['md', 'xs', null, null, 'md']}>
                    {referenceAmount.toFixed(2)} ֏
                </Text>
            </Stack>
        </HStack>
    );
};

const EditableControls: React.FC = () => {
    const {isEditing, getSubmitButtonProps, getCancelButtonProps, getEditButtonProps} = useEditableControls();

    return isEditing ? (
        <ButtonGroup mx="20px" justifyContent="center" size="sm">
            <IconButton
                variant="ghost"
                colorScheme="green"
                icon={<CheckIcon boxSize={5} />}
                aria-label="submit"
                {...getSubmitButtonProps()}
            />
            <IconButton
                colorScheme="red"
                variant="ghost"
                icon={<CloseIcon boxSize={4} />}
                aria-label="cancel"
                {...getCancelButtonProps()}
            />
        </ButtonGroup>
    ) : (
        <Flex justifyContent="center" ml="5px">
            <IconButton
                variant="ghost"
                aria-label="edit"
                size="sm"
                icon={<EditIcon boxSize={5} />}
                {...getEditButtonProps()}
            />
        </Flex>
    );
};

const Summary: React.FC = () => {
    const billInfoReference = useRecoilValue(currentReferenceCurrencyBillInfo);
    const billInfoTarget = useRecoilValue(currentTargetCurrencyBillInfo);
    const [orderName, setOrderName] = useRecoilState(currentOrderName);
    const orderId = useRecoilValue(currentOrderId);
    const [t] = useTranslation('common');

    const onUpdateName = useCallback(
        (value: string): void => {
            setOrderName(value);

            if (orderId !== null) {
                updateOrderById({id: orderId, name: value}).catch(console.error);
            }
        },
        [orderId],
    );

    return (
        <Box>
            <Heading as="h2" fontWeight="bold" marginBottom="5">
                <Editable
                    value={orderName ?? ''}
                    isPreviewFocusable={false}
                    display="flex"
                    alignItems="center"
                    onChange={onUpdateName}
                >
                    <EditablePreview />
                    <EditableInput />
                    <EditableControls />
                </Editable>
            </Heading>

            <Card>
                <Grid gap="6">
                    <Box gridColumn={[1, '1 / 3', null, 1]} gridRow={[1]}>
                        <KeyValue
                            label={t('Total')}
                            targetAmount={billInfoTarget.total}
                            referenceAmount={billInfoReference.total}
                        />
                    </Box>

                    <Box gridRow={[2, null, null, 1]}>
                        <KeyValue
                            label={t('Tax')}
                            targetAmount={billInfoTarget.tax}
                            referenceAmount={billInfoReference.tax}
                        />
                    </Box>
                    <Box gridRow={[3, 2, null, 1]}>
                        <KeyValue
                            label={t('Tips')}
                            targetAmount={billInfoTarget.tips}
                            referenceAmount={billInfoReference.tips}
                        />
                    </Box>

                    <Box gridRow={[4, 3, null, 1]}>
                        <KeyValue
                            label={t('Discount')}
                            targetAmount={billInfoTarget.discount}
                            referenceAmount={billInfoReference.discount}
                        />
                    </Box>
                    <Box gridRow={[5, 3, null, 1]}>
                        <KeyValue
                            label={t('summary.delivery')}
                            targetAmount={billInfoTarget.delivery}
                            referenceAmount={billInfoReference.delivery}
                        />
                    </Box>
                </Grid>
            </Card>
        </Box>
    );
};

export default Summary;

import React from 'react';
import {Icon, IconProps} from '@chakra-ui/react';

export const SaveIcon: React.FC<IconProps> = props => {
    return (
        <Icon viewBox="0 0 24 24" fill="none" {...props}>
            <path
                d="M15 20V15H9V20M4 12V18C4 19.1046 4.89543 20 6 20H18C19.1046 20 20 19.1046 20 18V16M4 8V6C4 4.89543 4.89543 4 6 4H14.1716C14.702 4 15.2107 4.21071 15.5858 4.58579L19.4142 8.41421C19.7893 8.78929 20 9.29799 20 9.82843V12"
                stroke="white"
                strokeWidth="1.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </Icon>
    );
};

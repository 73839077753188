import {selector} from 'recoil';
import {currentReferenceCurrencyAmount} from './currentReferenceCurrencyAmount';
import {currentReferenceCurrencyTax} from './currentReferenceCurrencyTax';

/**
 * [Reference Currency]
 * Получение значения общего счета с включенными налогами.
 */
export const currentReferenceCurrencyAmountWithTax = selector({
    key: 'CurrentReferenceCurrencyAmountWithTax',
    get: ({get}) => {
        const amount = get(currentReferenceCurrencyAmount);
        const tax = get(currentReferenceCurrencyTax);

        return amount + tax;
    },
});

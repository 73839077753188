import {parseCookie} from '../../utils/parseCookie';
import {BACKEND_HOST} from '../constants';

/**
 * Call the backend handlers by path
 *
 * @param path - api path
 * @param options - reques options
 */
export const fetchWithToken = async (path: string, options: RequestInit = {}): Promise<Response> => {
    const {cookie} = document;
    const cookies = parseCookie(cookie);

    return await fetch(`${BACKEND_HOST}${path}`, {
        ...options,
        headers: {
            Authorization: `Bearer ${cookies.auth_jwt}`,
            ...options.headers,
        },
    });
};

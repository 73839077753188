import {DeliveryInfo, DiscountInfo, TaxInfo, TipsInfo} from '../state';
import {Order, OrderItem, User} from '../types';
import {assertIsDefined} from './assertIsDefined';
import {calcDiscount} from './calcDiscount';
import {calcExchangeRate} from './calcExchangeRate';
import {calcTax} from './calcTax';

type WithAmout<T> = T & {amount: number};

type DiscountInfoWithAmount = WithAmout<DiscountInfo>;
type DeliveryInfoWithAmount = WithAmout<DeliveryInfo>;
type TaxInfoWithAmount = WithAmout<TaxInfo>;
type TipsInfoWithAmount = WithAmout<TipsInfo>;

export interface FullDataOrder {
    id: string;
    name: null | string;
    description?: string;
    users: User[];
    discountInfo: DiscountInfoWithAmount;
    deliveryInfo: DeliveryInfoWithAmount;
    taxInfo: TaxInfoWithAmount;
    tipsInfo: TipsInfoWithAmount;
    targetCurrencyAmount: number;
    targetCurrencyRawAmount: number;
    referenceCurrencyAmount: number;
    referenceCurrencyRawAmount: number;
    orderItems: OrderItem[];
    createdAt?: string;
}

export const calcFullDataFromOrder = ({
    id,
    name,
    description,
    users,
    deliveryInfo,
    discountInfo,
    taxInfo,
    tipsInfo,
    orderItems,
    manualExchangeRate,
    targetCurrencyAmount,
    rateCalculationType,
    createdAt,
}: Order): FullDataOrder => {
    assertIsDefined(id);
    // assertIsDefined(name);

    let rawAmount = 0;

    orderItems.forEach(({value = 0}) => {
        rawAmount += value;
    });

    const discountAmount = calcDiscount(rawAmount, rawAmount, discountInfo);
    let amount = rawAmount - discountAmount;
    const taxAmount = calcTax(amount, amount, taxInfo);
    const tipsAmount = tipsInfo.value ?? 0;
    const deliveryAmount = deliveryInfo.value ?? 0;
    amount = amount + tipsAmount + deliveryAmount + taxAmount;

    let exchangeRage;

    if (rateCalculationType === 'fromAmount') {
        exchangeRage = calcExchangeRate({
            targetCurrencyAmount: targetCurrencyAmount ?? 0,
            referenceCurrencyAmount: amount,
        });
    } else {
        if (manualExchangeRate === null || manualExchangeRate === 0) {
            exchangeRage = 0;
        } else {
            exchangeRage = 1 / manualExchangeRate;
        }
    }

    return {
        id,
        name,
        description,
        users,
        targetCurrencyAmount: amount * exchangeRage,
        targetCurrencyRawAmount: rawAmount * exchangeRage,
        referenceCurrencyAmount: amount,
        referenceCurrencyRawAmount: rawAmount,
        discountInfo: {
            ...discountInfo,
            amount: discountAmount * exchangeRage,
        },
        tipsInfo: {
            ...tipsInfo,
            amount: tipsAmount * exchangeRage,
        },
        taxInfo: {
            ...taxInfo,
            amount: taxAmount * exchangeRage,
        },
        deliveryInfo: {
            ...deliveryInfo,
            amount: deliveryAmount * exchangeRage,
        },
        orderItems,
        createdAt,
    };
};

import {atom} from 'recoil';
import {persistAppStorageAtomEffect} from '../effects';

export const currentTargetCurrencyAmountDefault = null;
/**
 * Сколько заплатили в "рублях".
 */
export const currentTargetCurrencyAmount = atom<number | null>({
    key: 'TargetCurrencyAmount',
    default: currentTargetCurrencyAmountDefault,
    effects: [persistAppStorageAtomEffect('currentTargetCurrencyAmount')],
});

import {APIOrder} from '../../types';
import {fetchWithToken} from '../helpers';

export const resolveOrders = async (): Promise<Array<Required<APIOrder>> | null> => {
    const response = await fetchWithToken(`/orders`);

    if (response.ok) {
        return await response.json();
    }

    return null;
};

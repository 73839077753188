import React, {ChangeEvent, useCallback} from 'react';
import {useTranslation} from 'react-i18next';

import {useRecoilState} from 'recoil';
import {currentTaxInfo} from '../../../state';
import {TaxValueType} from '../../../types';
import {CommonInput} from '../CommonInput';

export const TaxInput: React.FC = () => {
    const [t] = useTranslation('common');

    const [taxInfo, setTaxInfo] = useRecoilState(currentTaxInfo);

    const onChangeTaxValue = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value;
            const tax = value === '' ? null : Number(value);

            setTaxInfo({...taxInfo, value: tax});
        },
        [taxInfo],
    );

    const onChangeTaxType = useCallback(
        (event: ChangeEvent<HTMLSelectElement>) => {
            const value = event.target.value as TaxValueType;

            setTaxInfo({...taxInfo, type: value});
        },
        [taxInfo],
    );

    return (
        <CommonInput
            value={taxInfo.value}
            type={taxInfo.type}
            title={t('Tax')}
            placeholder={t('Enter tax')}
            onChangeInput={onChangeTaxValue}
            onChangeType={onChangeTaxType}
        />
    );
};

import {selector} from 'recoil';
import {currentReferenceCurrencyDiscount} from './currentReferenceCurrencyDiscount';
import {currentReferenceCurrencyRawAmount} from './currentReferenceCurrencyRawAmount';

/**
 * [Reference Currency]
 * Получение значения общего счета с учетом скидки, но без налогов и чаевых.
 */
export const currentReferenceCurrencyAmount = selector({
    key: 'CurrentReferenceCurrencyAmount',
    get: ({get}) => {
        const rawAmount = get(currentReferenceCurrencyRawAmount);
        const discount = get(currentReferenceCurrencyDiscount);

        return rawAmount - discount;
    },
});

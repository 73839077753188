import cn from 'classnames';
import React from 'react';
import {Box, BoxProps} from '@chakra-ui/react';

import styles from './Card.module.css';

interface ICardProps extends BoxProps {
    children: React.ReactNode;
}

const Card: React.FC<ICardProps> = props => {
    const {children, ...restProps} = props;

    return (
        <Box {...restProps} className={cn(styles.root, restProps.className)} px={7} py={5}>
            {children}
        </Box>
    );
};

export default Card;

import React, {FC, PropsWithChildren} from 'react';
import {Box} from '@chakra-ui/react';
import {Header} from '../../components/Header';

type Props = PropsWithChildren<{
    withLogin?: boolean;
}>;

export const Page: FC<Props> = ({children, withLogin}) => (
    <Box>
        <Header withLogin={withLogin} />

        <Box p={[4, 6]}>{children}</Box>
    </Box>
);

import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useRecoilState} from 'recoil';
import {Button, VStack, Text} from '@chakra-ui/react';

import {DetalizationItem} from '../DetalizationItem';
import {createOrderItem} from '../../entities/orderItem';
import {currentOrderItems} from '../../state/orderItems';
import {OrderItem} from '../../types';

interface IDetalizationListProps {
    items: OrderItem[];
}

const DetalizationList: React.FC<IDetalizationListProps> = props => {
    const [t] = useTranslation('common');
    const {items} = props;
    const [orderItems, setOrderItems] = useRecoilState(currentOrderItems);
    const addOrderItem = useCallback(() => {
        const items = [...orderItems, createOrderItem({users: [], description: ''})];

        setOrderItems(items);
    }, [orderItems]);

    return (
        <VStack align="stretch" spacing="7">
            {items.length === 0 && (
                <Text fontSize="lg" fontWeight="bold" marginTop={3}>
                    Пока не добавлено не одной позиции 🤷
                </Text>
            )}

            {items.length > 0 && (
                <VStack align="stretch" spacing="4">
                    {items.map(item => (
                        <DetalizationItem
                            key={item.id}
                            id={item.id}
                            name={item.description ?? ''}
                            amount={item.value}
                            users={item.users}
                        />
                    ))}
                </VStack>
            )}

            <Button size="lg" colorScheme="purple" onClick={() => addOrderItem()}>
                {t('Add')}
            </Button>
        </VStack>
    );
};

export default DetalizationList;

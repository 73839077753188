import {selector} from 'recoil';
import {resolveUserContacts} from '../resolvers';

/**
 * Получения списка контактов авторизованного пользователя.
 */
export const currentMainUserContactsQuery = selector({
    key: 'CurrentMainUserContactsQuery',
    get: resolveUserContacts,
});

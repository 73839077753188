import {Order, OrderItem, APIOrder} from '../../../types';

export const prepareOrderRequestData = (order: Partial<Order>): Partial<APIOrder> => {
    const {
        name,
        description,
        orderItems,
        targetCurrencyAmount,
        rateCalculationType,
        taxInfo,
        discountInfo,
        deliveryInfo,
        tipsInfo,
        manualExchangeRate,
        users,
    } = order;

    // const orderUsers: UserRequestData[] = users.map(user => ({id: user.id, username: user.name}));

    return {
        name: name ?? undefined,
        description,
        users: users?.map(user => user.id),
        targetCurrencyAmount: targetCurrencyAmount ?? undefined,
        targetCurrencyRate: manualExchangeRate ?? undefined,
        rateCalculationType,
        taxValue: taxInfo?.value ?? undefined,
        taxType: taxInfo?.type,
        discountValue: discountInfo?.value ?? undefined,
        discountType: discountInfo?.type,
        tipsValue: tipsInfo?.value ?? undefined,
        tipsType: tipsInfo?.type,
        delivery: deliveryInfo?.value ?? undefined,
        items: orderItems?.map(({description, users, id, value}: OrderItem) => ({
            id,
            name: description,
            users,
            amount: value,
        })),
    };
};

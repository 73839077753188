import localForage from 'localforage';
import {AtomEffect, DefaultValue} from 'recoil';
import {appDataStore} from '../utils';

/** Check if there's an initial value persisted and load it on set  */
const loadPersisted = async <T>({
    key,
    setSelf,
}: {
    key: string;
    setSelf: Parameters<AtomEffect<T>>['0']['setSelf'];
}): Promise<void> => {
    const savedValue = await localForage.getItem<string>(key);

    if (savedValue != null) {
        setSelf(JSON.parse(savedValue));
    }
};

/**
 * Localstorage Atom Effect
 *
 * Add to `effects_UNSTABLE` to persist atom.
 * Side-effect for Atom Manipulating
 * @see https://recoiljs.org/docs/guides/atom-effects/
 */
export const persistAppStorageAtomEffect =
    <T>(key: string): AtomEffect<T> =>
    ({setSelf, onSet}) => {
        loadPersisted({key, setSelf}).catch(console.error);

        onSet(newValue => {
            (async () => {
                if (newValue instanceof DefaultValue) {
                    await appDataStore.removeItem(key);
                } else {
                    await appDataStore.setItem(key, JSON.stringify(newValue));
                }
            })().catch(console.error);
        });
    };

import {atom} from 'recoil';
import {persistAppStorageAtomEffect, updateUserContactsEffect} from '../effects';
import {User} from '../types';

export const defaultUserContacts = [];

export const currentUserContacts = atom<User[]>({
    key: 'CurrentUserContacts',
    default: defaultUserContacts,
    effects: [persistAppStorageAtomEffect('currentUserContacts'), updateUserContactsEffect()],
});

import {TaxInfo} from '../state';

/**
 * Рассчет налога исходя из потраченого.
 */
export const calcTax = (amount: number, totalAmount: number, taxInfo: TaxInfo): number => {
    const value = taxInfo.value ?? 0;
    const type = taxInfo.type;

    if (type === 'percent') {
        return (amount * value) / 100;
    }

    if (amount === totalAmount) {
        return value;
    }

    return (amount / totalAmount) * value;
};

import {APIUser, Order, OrderItem, APIOrderItem, APIOrder} from '../../types';
import {mapAPIUserToUser} from './mapAPIUserToUser';

export const mapToOrder = (orderRequest: Required<APIOrder>): Order => {
    const orderItems = orderRequest.items.map(({name, users, id, amount}: APIOrderItem) => ({
        id,
        description: name,
        users: (users as unknown as APIUser[]).map(user => user.id),
        value: amount,
    })) as OrderItem[];

    const {targetCurrencyRate, targetCurrencyAmount, rateCalculationType, id, name, description, createdAt} =
        orderRequest;

    return {
        id,
        name,
        description,
        targetCurrencyAmount,
        manualExchangeRate: targetCurrencyRate,
        rateCalculationType,
        orderItems,
        createdAt,
        users: (orderRequest.users as unknown as APIUser[]).map(mapAPIUserToUser),
        taxInfo: {
            value: orderRequest.taxValue,
            type: orderRequest.taxType,
        },
        tipsInfo: {
            value: orderRequest.tipsValue,
            type: orderRequest.tipsType,
        },
        deliveryInfo: {
            value: orderRequest.delivery,
        },
        discountInfo: {
            value: orderRequest.discountValue,
            type: orderRequest.discountType,
        },
    };
};

import React from 'react';
import {Icon, IconProps} from '@chakra-ui/react';

export const YandexIcon: React.FC<IconProps> = props => {
    return (
        <Icon viewBox="0 0 44 44" fill="none" {...props}>
            <rect width="44" height="44" rx="22" fill="#FC3F1D" />
            <path
                d="M25.2438 12.3208H23.0173C19.2005 12.3208 17.292 14.2292 17.292 17.0919C17.292 20.2726 18.5643 21.863 21.427 23.7714L23.6535 25.3618L17.292 35.222H12.2029L18.2463 26.316C14.7475 23.7714 12.839 21.5449 12.839 17.41C12.839 12.3208 16.3378 8.82202 23.0173 8.82202H29.6969V35.222H25.2438V12.3208Z"
                fill="white"
            />
        </Icon>
    );
};

/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable react/prop-types */
import arrayMutators from 'final-form-arrays';
import {FieldArray} from 'react-final-form-arrays';
import {Button, VStack, Stack, IconButton, Avatar, HStack} from '@chakra-ui/react';
import {Form as FinalForm, Field} from 'react-final-form';
import React, {FC, useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {createUser} from '../../../entities';
import {User} from '../../../types';
import {TextField} from '../../form/TextField';
import {DeleteIcon} from '@chakra-ui/icons';
import {useRecoilState} from 'recoil';
import {currentUserContacts} from '../../../state/currentUserContacts';

interface Props {
    className?: string;
    users: User[];
    onSubmit?: () => void;
}

interface IFormValues {
    users: Array<Partial<User>>;
}

const getContactsMap = (contacts: User[]): Record<string, User> => {
    const contactsMap: Record<string, User> = {};

    contacts.forEach(user => {
        contactsMap[user.id] = user;
    });

    return contactsMap;
};

export const EditContactsList: FC<Props> = ({onSubmit}) => {
    const [t] = useTranslation('common');
    const [contacts, setContacts] = useRecoilState(currentUserContacts);
    const currentUsersMap = getContactsMap(contacts);

    const initialValues = useMemo(() => ({users: contacts}), [contacts]);

    const submit = useCallback((values: IFormValues) => {
        const editedUsers = values.users.map(user => {
            if (user.id === undefined) {
                return createUser({name: '', ...user});
            }

            return {
                ...currentUsersMap[user.id],
                ...user,
            };
        });

        setContacts(editedUsers);

        if (onSubmit != null) {
            onSubmit();
        }
    }, []);

    const required = useCallback((value?: string) => ((value ?? '').length > 0 ? undefined : t('field.required')), []);

    return (
        <FinalForm
            onSubmit={submit}
            initialValues={initialValues}
            mutators={{
                ...arrayMutators,
            }}
        >
            {props => {
                const {handleSubmit, form, values} = props;
                const {mutators} = form;

                return (
                    <form onSubmit={handleSubmit} autoComplete="">
                        <VStack spacing="2">
                            <FieldArray name="users">
                                {({fields}) =>
                                    fields.map((field, idx) => (
                                        <Stack key={field} direction={['column', 'row']} py={2} w="100%">
                                            <Avatar name={values.users[idx].name} mr="10px" />

                                            <Field
                                                name={`${field}.name`}
                                                component={TextField}
                                                variant="flushed"
                                                validate={required}
                                                alignSelf="center"
                                                placeholder={t('user-editor.username.placeholder')}
                                                w="100%"
                                            />

                                            <Field
                                                name={`${field}.email`}
                                                component={TextField}
                                                variant="flushed"
                                                alignSelf="center"
                                                placeholder={'Введите email'}
                                                w="100%"
                                            />

                                            <IconButton
                                                variant="outline"
                                                aria-label="remove user"
                                                onClick={() => fields.remove(idx)}
                                                icon={<DeleteIcon />}
                                                colorScheme="red"
                                                size="md"
                                                w={['100%', 'auto']}
                                            />
                                        </Stack>
                                    ))
                                }
                            </FieldArray>
                        </VStack>

                        <HStack spacing="5" marginTop={6}>
                            <Button size="md" colorScheme="gray" w="100%" onClick={() => mutators.push('users', {})}>
                                {t('Add')}
                            </Button>

                            <Button type="submit" colorScheme="purple" w="full">
                                Oк
                            </Button>
                        </HStack>
                    </form>
                );
            }}
        </FinalForm>
    );
};

import React from 'react';
import {Icon} from '@chakra-ui/react';

interface Props {
    /**
     * Size in pixels.
     */
    size?: number;
}

export const Logo: React.FC<Props> = ({size = 40}) => (
    <Icon width={`${size}px`} height={`${size}px`} viewBox="0 0 109 109" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask
            id="mask0_1723_56"
            // style="mask-type:alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="109"
            height="109"
        >
            <rect width="109" height="109" rx="10" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_1723_56)">
            <rect width="109" height="109" rx="10" fill="url(#paint0_linear_1723_56)" />
            <path
                d="M26.968 88V20.8H56.056C62.072 20.8 67.256 21.792 71.608 23.776C75.96 25.696 79.32 28.48 81.688 32.128C84.056 35.776 85.24 40.128 85.24 45.184C85.24 50.176 84.056 54.496 81.688 58.144C79.32 61.728 75.96 64.48 71.608 66.4C67.256 68.32 62.072 69.28 56.056 69.28H35.608L42.52 62.464V88H26.968ZM69.688 88L52.888 63.616H69.496L86.488 88H69.688ZM42.52 64.192L35.608 56.896H55.192C59.992 56.896 63.576 55.872 65.944 53.824C68.312 51.712 69.496 48.832 69.496 45.184C69.496 41.472 68.312 38.592 65.944 36.544C63.576 34.496 59.992 33.472 55.192 33.472H35.608L42.52 26.08V64.192Z"
                fill="white"
            />
        </g>
        <defs>
            <linearGradient
                id="paint0_linear_1723_56"
                x1="0"
                y1="54.5"
                x2="109"
                y2="54.5"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#4776E6" />
                <stop offset="1" stopColor="#8E54E9" />
            </linearGradient>
        </defs>
    </Icon>
);

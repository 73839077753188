import React, {ChangeEvent, useCallback} from 'react';
import {useTranslation} from 'react-i18next';

import {useRecoilState} from 'recoil';
import {currentDiscountInfo} from '../../../state';
import {DiscountValueType} from '../../../types';
import {CommonInput} from '../CommonInput';

export const DiscountInput: React.FC = () => {
    const [t] = useTranslation('common');

    const [discountInfo, setDiscountInfo] = useRecoilState(currentDiscountInfo);

    const onChangeDiscountValue = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value;
            const discount = value === '' ? null : Number(value);

            setDiscountInfo({...discountInfo, value: discount});
        },
        [discountInfo],
    );

    const onChangeDiscountType = useCallback(
        (event: ChangeEvent<HTMLSelectElement>) => {
            const value = event.target.value as DiscountValueType;

            setDiscountInfo({...discountInfo, type: value});
        },
        [discountInfo],
    );

    return (
        <CommonInput
            value={discountInfo.value}
            type={discountInfo.type}
            title={t('Discount')}
            placeholder={t('Enter discount')}
            onChangeInput={onChangeDiscountValue}
            onChangeType={onChangeDiscountType}
        />
    );
};

import {selector} from 'recoil';
import {activeUsers} from './activeUsers';
import {User} from '../types';

/**
 * Получение мапы пользователей: id -> User.
 */
export const usersMap = selector({
    key: 'UsersMap',
    get: ({get}) => {
        const users = get(activeUsers);

        const usersMap: Record<string, User> = {};

        users.forEach(user => {
            usersMap[user.id] = user;
        });

        return usersMap;
    },
});

import {Text} from '@chakra-ui/react';
import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useRecoilState, useRecoilValue} from 'recoil';
import {currentExchangeRate} from '../../selectors';
import {currentTargetCurrencyAmount} from '../../state';
import {CurrencyInput} from '../CurrencyInput';

export const CurrencyRateFromAmountContent = (): JSX.Element => {
    const [t] = useTranslation('common');
    const exchangeRate = useRecoilValue(currentExchangeRate);
    const [targetCurrencyAmount, setTargetCurrencyAmount] = useRecoilState(currentTargetCurrencyAmount);

    const onChangeTargetCurrencyAmount = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const amount = value === '' ? null : Number(value);

        setTargetCurrencyAmount(amount);
    }, []);

    const inputValue = targetCurrencyAmount === null ? '' : targetCurrencyAmount;
    const amdToRub = 1 / exchangeRate;
    const exchangeText =
        amdToRub > 0 && isFinite(amdToRub)
            ? `1 ₽ = ${amdToRub.toFixed(2)} ֏`
            : t('Exchange rate has not been calculated yet 🤬');
    return (
        <>
            <Text fontWeight="bold">{t('rateHelpText')}</Text>

            <CurrencyInput
                value={inputValue}
                placeholder={t('rateAutoPlaceholder')}
                currency="RUB"
                onChange={onChangeTargetCurrencyAmount}
            />

            <Text fontWeight="bold" fontSize="2xl">
                {exchangeText}
            </Text>
        </>
    );
};

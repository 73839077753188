import React from 'react';
import {useTranslation} from 'react-i18next';
import {Heading, VStack, Grid, Box} from '@chakra-ui/react';

import {Card} from '../Card';
import {DeliveryInput} from './DeliveryInput';
import {DiscountInput} from './DiscountInput';
import {TipsInput} from './TipsInput';
import {TaxInput} from './TaxInput';

const TaxAndTips: React.FC = () => {
    const [t] = useTranslation('common');

    return (
        <Box display="flex" flexDir="column" flexGrow={2}>
            <Heading marginBottom={5}>{t('Correction')}</Heading>

            <Card flexGrow={1}>
                <VStack spacing={4} align="stretch">
                    <Grid gap={4}>
                        <Box gridRow={[1]}>
                            <TaxInput />
                        </Box>
                        <Box gridRow={[2, 1, 2, 1]}>
                            <TipsInput />
                        </Box>
                        <Box gridRow={[3, 2, 3, 2]}>
                            <DiscountInput />
                        </Box>
                        <Box gridRow={[4, 2, 4, 2]}>
                            <DeliveryInput />
                        </Box>
                    </Grid>
                </VStack>
            </Card>
        </Box>
    );
};

export default TaxAndTips;

import {selector} from 'recoil';
import {currentDeliveryInfo} from '../../state';

/**
 * [Reference Currency]
 * Получение значения доставки.
 */
export const currentReferenceCurrencyDelivery = selector({
    key: 'CurrentReferenceCurrencyDelivery',
    get: ({get}) => {
        const deliveryInfo = get(currentDeliveryInfo);

        if (deliveryInfo?.value !== null && deliveryInfo?.value !== undefined) {
            return deliveryInfo.value;
        }

        return 0;
    },
});

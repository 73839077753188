import {selector} from 'recoil';
import {currentDiscountInfo, currentTaxInfo} from '../../state';
import {BillInfo} from '../../types';
import {calcTax} from '../../utils/calcTax';
import {currentReferenceCurrencyRawAmount} from './currentReferenceCurrencyRawAmount';
import {currentReferenceCurrencyTipsPerUser} from './currentReferenceCurrencyTipsPerUser';
import {destributedOrderItems} from '../currentOrderItems';
import {activeUsers} from '../activeUsers';
import {calcDiscount} from '../../utils';
import {currentReferenceCurrencyAmount} from './currentReferenceCurrencyAmount';
import {currentReferenceCurrencyDeliveryPerUser} from './currentReferenceCurrencyDeliveryPerUser';
import {activeUsersIdWithOrders} from '../activeUsersIdWithOrder';

export type BillByUser = Record<string, BillInfo>;

/**
 * [Reference Currency]
 * Получения мапы с счетами разделенными по пользователям.
 */
export const currentReferenceCurrencyBillByUser = selector({
    key: 'CurrentReferenceCurrencyBillByUser',
    get: ({get}) => {
        const orderItems = get(destributedOrderItems);
        const users = get(activeUsers);
        const usersIdWithOrders = get(activeUsersIdWithOrders);
        const tipsPerUser = get(currentReferenceCurrencyTipsPerUser);
        const deliveryPerUser = get(currentReferenceCurrencyDeliveryPerUser);
        const rawAmount = get(currentReferenceCurrencyRawAmount);
        const amountWithDiscount = get(currentReferenceCurrencyAmount);
        const taxInfo = get(currentTaxInfo);
        const discountInfo = get(currentDiscountInfo);

        const orderByUser: BillByUser = {};

        /**
         * Заплняем дефолтными значениями.
         */
        users.forEach(user => {
            orderByUser[user.id] = {
                value: 0,
                discount: 0,
                delivery: 0,
                tax: 0,
                tips: 0,
                total: 0,
            };
        });

        orderItems.forEach(({value = 0, users}) => {
            const valuePerUser = value / users.length;

            users.forEach(userId => {
                const currentInfo = orderByUser[userId];

                currentInfo.value += valuePerUser;
            });
        });

        /**
         * Рассчитваем значения с налогом и чаевыми.
         */
        usersIdWithOrders.forEach(userId => {
            const currentInfo = orderByUser[userId];

            const {value} = currentInfo;

            const discount = calcDiscount(value, rawAmount, discountInfo);
            const tax = calcTax(value - discount, amountWithDiscount, taxInfo);

            currentInfo.discount = discount;
            currentInfo.tax = tax;
            currentInfo.delivery = deliveryPerUser;
            currentInfo.tips = tipsPerUser;
            currentInfo.total = currentInfo.value - discount + tax + tipsPerUser + deliveryPerUser;
        });

        return orderByUser;
    },
});

import {AtomEffect} from 'recoil';
import {mapUserToAPIUser} from '../entities';
import {currentLoggedUserQuery, currentMainUserContactsQuery} from '../queries';
import {createUserContacts, deleteUserContactById} from '../resolvers';
import {User, APIUser} from '../types';

export const updateUserContactsEffect =
    (): AtomEffect<User[]> =>
    ({onSet, getPromise}) => {
        onSet((newValue): void => {
            (async () => {
                const authUser = await getPromise(currentLoggedUserQuery);

                if (authUser != null) {
                    const contacts = await getPromise(currentMainUserContactsQuery);

                    /**
                     * Create new contact block.
                     */
                    const contactsMap: Record<string, boolean> = {};

                    if (contacts === null) return;

                    contacts.forEach(contact => {
                        contactsMap[contact.id] = true;
                    });

                    const notCreatedContacts = newValue.filter(
                        /**
                         * Не добавляем в контакты самого себя.
                         */
                        user => {
                            return contactsMap[user.id] === undefined && user.id !== authUser.id;
                        },
                    );

                    const users: APIUser[] = notCreatedContacts.map(mapUserToAPIUser);

                    if (users.length > 0) {
                        createUserContacts(users).catch(console.error);
                    }

                    /**
                     * Delete contact block.
                     */
                    const newContactsMap: Record<string, boolean> = {};

                    newValue.forEach(contact => {
                        newContactsMap[contact.id] = true;
                    });
                    const deletedContacts = contacts.filter(user => newContactsMap[user.id] === undefined);

                    deletedContacts.forEach(contact => {
                        deleteUserContactById(contact.id).catch(console.error);
                    });

                    /**
                     * Update contact block.
                     */
                    // Not implemented
                }
            })().catch(console.error);
        });
    };

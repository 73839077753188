import React, {ReactEventHandler, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
    Box,
    IconButton,
    useDisclosure,
    FormLabel,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalHeader,
    ModalCloseButton,
    Button,
    ModalFooter,
    Input,
    useToast,
    VStack,
    Spinner,
} from '@chakra-ui/react';
import {LinkIcon} from '@chakra-ui/icons';
import {useRecoilCallback} from 'recoil';
import {currentAppData} from '../../selectors';
import {assertIsDefined, copyToClipboard} from '../../utils';
import {createOrder, updateOrderById} from '../../resolvers';

interface ErrorContentProps {
    url: string;
    onClose: ReactEventHandler;
}

const ErrorContent: React.FC<ErrorContentProps> = ({url, onClose}) => {
    const [t] = useTranslation('common');

    return (
        <ModalContent>
            <ModalHeader>{t('Share link created successfully 😎')}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <FormLabel fontWeight="bold">{t('Your link to manual copy:')}</FormLabel>
                {url !== '' && <Input value={url} onChange={() => {}} />}

                {url === '' && (
                    <VStack paddingY={2}>
                        <Spinner colorScheme="purple" size="md" />
                    </VStack>
                )}
            </ModalBody>

            <ModalFooter>
                <Button colorScheme="purple" mr={3} onClick={onClose}>
                    {t('Close')}
                </Button>
            </ModalFooter>
        </ModalContent>
    );
};

export const ShareAction: React.FC = () => {
    const [url, setUrl] = useState('');
    const {isOpen, onOpen, onClose} = useDisclosure();
    const toast = useToast();

    const onShareLink = useRecoilCallback(
        ({snapshot}): ReactEventHandler =>
            async () => {
                const appOrder = await snapshot.getPromise(currentAppData);

                const isNewOrder = appOrder.id === null;

                let order;

                if (isNewOrder) {
                    order = await createOrder(appOrder);
                } else {
                    order = await updateOrderById(appOrder);
                }

                if (order !== null) {
                    assertIsDefined(order.id);

                    const url = `${window.location.origin}?share=${order.id}`;

                    const responseClck = await fetch(`https://clck.ru/--?url=${url}`);
                    const shortUrl = await responseClck.text();

                    setUrl(shortUrl);

                    copyToClipboard(shortUrl)
                        .then(() =>
                            toast({
                                title: 'Share link copied successfully 😎',
                                position: 'top-right',
                                status: 'success',
                                duration: 2000,
                            }),
                        )
                        .catch(error => {
                            console.error(error);
                            onOpen();
                        });
                } else {
                    toast({
                        title: 'Для того чтобы пошарить ссылку, авторизуйтесь!',
                        position: 'top-right',
                        status: 'error',
                        duration: 2000,
                    });
                }
            },
        [],
    );

    return (
        <Box>
            <IconButton colorScheme="purple" aria-label="share" icon={<LinkIcon />} onClick={onShareLink} />

            <Modal size="xl" isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />

                <ErrorContent url={url} onClose={onClose} />
            </Modal>
        </Box>
    );
};

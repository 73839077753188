import React from 'react';
import {FormControl, FormErrorMessage, Input, InputProps} from '@chakra-ui/react';
import {FieldRenderProps} from 'react-final-form';

export interface TextFieldProps extends FieldRenderProps<string>, InputProps {}

const TextField: React.FC<TextFieldProps> = props => {
    const {meta, input, label, ...inputProps} = props;
    const hasError = Boolean(meta.error) && Boolean(meta.touched);

    return (
        <FormControl isInvalid={hasError}>
            <Input {...input} onChange={event => input.onChange(event.target.value)} {...inputProps} />
            {hasError && <FormErrorMessage>{meta.error}</FormErrorMessage>}
        </FormControl>
    );
};

export default TextField;

import React from 'react';
import {Stack, Text, Link} from '@chakra-ui/react';

const Footer: React.FC = () => {
    return (
        <Stack h={['auto', '64px']} direction="row" px="7" py="5" align="center" spacing="5">
            <Text>© {new Date().getFullYear()} «Recklify»</Text>
            <Link href="https://forms.yandex.ru/u/6353e1cec417f3787d4e9ec2/" isExternal color="purple.600">
                Обратная связь
            </Link>
        </Stack>
    );
};

export default Footer;

import React, {ChangeEvent, useCallback} from 'react';
import {useTranslation} from 'react-i18next';

import {useRecoilState} from 'recoil';
import {currentTipsInfo} from '../../../state';
import {TipsValueType} from '../../../types';
import {CommonInput} from '../CommonInput';

export const TipsInput: React.FC = () => {
    const [t] = useTranslation('common');

    const [tipsInfo, setTipsInfo] = useRecoilState(currentTipsInfo);

    const onChangeTipsValue = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value;
            const tips = value === '' ? null : Number(value);

            setTipsInfo({...tipsInfo, value: tips});
        },
        [tipsInfo],
    );

    const onChangeTipsType = useCallback(
        (event: ChangeEvent<HTMLSelectElement>) => {
            const value = event.target.value as TipsValueType;

            setTipsInfo({...tipsInfo, type: value});
        },
        [tipsInfo],
    );

    return (
        <CommonInput
            value={tipsInfo.value}
            type={tipsInfo.type}
            title={t('Tips')}
            placeholder={t('Enter tips')}
            onChangeInput={onChangeTipsValue}
            onChangeType={onChangeTipsType}
        />
    );
};

import {selector} from 'recoil';
import {currentReferenceCurrencyAmountWithTax} from './currentReferenceCurrencyAmountWithTax';
import {currentReferenceCurrencyDelivery} from './currentReferenceCurrencyDelivery';
import {currentReferenceCurrencyTips} from './currentReferenceCurrencyTips';

/**
 * [Reference Currency]
 * Получение значения общего счета с включенными налогами и чаевыми.
 */
export const currentReferenceCurrencyAmountTotal = selector({
    key: 'CurrentReferenceCurrencyAmountTotal',
    get: ({get}) => {
        const amountWithTax = get(currentReferenceCurrencyAmountWithTax);
        const tips = get(currentReferenceCurrencyTips) ?? 0;
        const delivery = get(currentReferenceCurrencyDelivery);

        return amountWithTax + tips + delivery;
    },
});

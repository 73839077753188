import {selector} from 'recoil';
import {currentTaxInfo} from '../../state/taxInfo';
import {calcTax} from '../../utils/calcTax';
import {currentReferenceCurrencyAmount} from './currentReferenceCurrencyAmount';

/**
 * [Reference Currency]
 * Получение значения налогов.
 */
export const currentReferenceCurrencyTax = selector({
    key: 'CurrentReferenceCurrencyTax',
    get: ({get}) => {
        const taxInfo = get(currentTaxInfo);
        const amount = get(currentReferenceCurrencyAmount);

        return calcTax(amount, amount, taxInfo);
    },
});

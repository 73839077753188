import {SimpleGridProps, StackProps, StyleProps} from '@chakra-ui/react';

export const gridLayout: SimpleGridProps = {
    columns: [1, 2],
    alignItems: 'center',
    justifyContent: 'center',
    height: ['calc(100vh - 107px)'],
    spacing: [0, 20],
};

export const image: StyleProps = {
    boxSize: ['xs', 'lg'],
    justifySelf: ['center', 'end'],
};

export const text: StyleProps = {
    fontSize: 'xl',
    color: 'gray',
    paddingX: ['10px', '0'],
};

export const title: StyleProps = {
    fontSize: '5xl',
};

export const descriptionBlock: StackProps = {
    maxWidth: 'lg',
    w: 'full',
    spacing: 6,
    display: 'flex',
    height: ['100%', 'auto'],
    alignItems: ['center', 'flex-start'],
};

import {atom} from 'recoil';

export interface IUsersEditor {
    isOpen: boolean;
}

export const usersEditor = atom<IUsersEditor>({
    key: 'UsersEditor',
    default: {
        isOpen: false,
    },
});

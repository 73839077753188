import {atom} from 'recoil';
import {persistAppStorageAtomEffect} from '../effects';

export interface DiscountInfo {
    type: 'percent' | 'absolute';
    value: number | null;
}

export const currentDiscountInfoDefault: DiscountInfo = {
    type: 'percent',
    value: null,
};

export const currentDiscountInfo = atom<DiscountInfo>({
    key: 'CurrentDiscountInfo',
    default: currentDiscountInfoDefault,
    effects: [persistAppStorageAtomEffect('currentDiscountInfo')],
});

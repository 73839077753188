import {selector} from 'recoil';
import {parseCookie} from '../utils';

/**
 * Получение флага об аторизации юзера.
 */
export const isAuthUser = selector({
    key: 'IsAuthUser',
    get: () => {
        /**
         * Временно считаем что любой пользователь с jwt токеном - авторизован.
         */
        const {cookie} = document;
        const cookies = parseCookie(cookie);

        return Boolean(cookies.auth_jwt);
    },
});

import {Order} from '../types';
import {appDataStore} from '../utils';

type GetAppDataFunction = () => Promise<Partial<Order>>;

export const useGetAppData = (): GetAppDataFunction => {
    return async () => {
        const promises = [
            appDataStore.getItem('orderName'),
            appDataStore.getItem('currentSelectedParticipants'),
            appDataStore.getItem('currentUserContacts'),
            appDataStore.getItem('currentDiscountInfo'),
            appDataStore.getItem('currentDeliveryInfo'),
            appDataStore.getItem('currentTaxInfo'),
            appDataStore.getItem('currentTipsInfo'),
            appDataStore.getItem('currentRubAmount'),
            appDataStore.getItem('currentOrderItems'),
            appDataStore.getItem('currentManualExchangeRate'),
            appDataStore.getItem('currentRateCalculationType'),
        ];

        const [
            name,
            users,
            contacts,
            discountInfo,
            deliveryInfo,
            taxInfo,
            tipsInfo,
            rubAmount,
            orderItems,
            manualExchangeRate,
            rateCalculationType,
        ] = await Promise.all(promises);

        const localAppData: $TSFixME = {
            name: JSON.parse(name as string),
            users: JSON.parse(users as string),
            contacts: JSON.parse(contacts as string),
            discountInfo: JSON.parse(discountInfo as string),
            deliveryInfo: JSON.parse(deliveryInfo as string),
            taxInfo: JSON.parse(taxInfo as string),
            tipsInfo: JSON.parse(tipsInfo as string),
            rubAmount: JSON.parse(rubAmount as string),
            orderItems: JSON.parse(orderItems as string),
            manualExchangeRate: JSON.parse(manualExchangeRate as string),
            rateCalculationType: JSON.parse(rateCalculationType as string),
        };

        const result: $TSFixME = {};

        Object.keys(localAppData).forEach(key => {
            if (localAppData[key] != null) {
                result[key] = localAppData[key];
            }
        });

        return result;
    };
};

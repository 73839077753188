import {OrderItem} from '../types';
import {generateId} from '../utils';

interface CreateUserParams {
    value?: number;
    users: string[];
    description?: string;
}

export const createOrderItem = ({value, users, description}: CreateUserParams): OrderItem => ({
    id: generateId(),
    value,
    users,
    description,
});

import {atom} from 'recoil';
import {persistAppStorageAtomEffect} from '../effects';

export interface TaxInfo {
    type: 'percent' | 'absolute';
    value: number | null;
}

export const currentTaxInfoDefault: TaxInfo = {
    type: 'percent',
    value: 10,
};

export const currentTaxInfo = atom<TaxInfo>({
    key: 'CurrentTaxInfo',
    default: currentTaxInfoDefault,
    effects: [persistAppStorageAtomEffect('currentTaxInfo')],
});

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {App} from './App';
import reportWebVitals from './reportWebVitals';
import {RecoilRoot} from 'recoil';
import {ChakraProvider, extendTheme, withDefaultColorScheme} from '@chakra-ui/react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import * as serviceWorker from './serviceWorkerRegistration';
import {AuthPage} from './pages/AuthPage';
import {OrdersPage} from './pages/OrdersPage';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const customTheme = extendTheme(withDefaultColorScheme({colorScheme: 'purple'}));

root.render(
    <RecoilRoot>
        <React.StrictMode>
            <ChakraProvider theme={customTheme}>
                <Router>
                    <Routes>
                        <Route path="/auth" element={<AuthPage />} />

                        <Route path="/api/auth/yandex" element={null} />

                        <Route path="/orders" element={<OrdersPage />} />

                        <Route path="*" element={<App />} />
                    </Routes>
                </Router>
            </ChakraProvider>
        </React.StrictMode>
    </RecoilRoot>,
);

serviceWorker.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from 'react';
import {useTranslation} from 'react-i18next';
import {Avatar, Text, VStack, HStack, Box, StackDivider, Spacer} from '@chakra-ui/react';

import {Card} from '../Card';
import {useRecoilValue} from 'recoil';
import {currentReferenceCurrencyBillByUser, currentTargetCurrencyBillByUser} from '../../selectors';

interface IUserDetailsProps {
    id: string;
    name: string;
}

const KeyValue: React.FC<{label: string; targetAmount: number; referenceAmount: number}> = props => {
    const {label, targetAmount, referenceAmount} = props;

    return (
        <HStack justifyContent="space-between" w="100%">
            <Text fontSize={['xl', 'md', null, 'xl']} color="GrayText">
                {label}
            </Text>

            <Box display="flex" flexDir="column" alignItems="flex-end">
                <Text fontSize={['xl', 'md', null, 'xl']}>{targetAmount.toFixed(2)} ₽ </Text>

                <Text fontSize={['sm', 'xs', null, 'sm']} color="GrayText" align="right">
                    {referenceAmount.toFixed(2)} ֏
                </Text>
            </Box>
        </HStack>
    );
};

const UserDetails: React.FC<IUserDetailsProps> = props => {
    const {id, name} = props;
    const [t] = useTranslation('common');

    const billByUserTarget = useRecoilValue(currentTargetCurrencyBillByUser);
    const billByUserReference = useRecoilValue(currentReferenceCurrencyBillByUser);

    return (
        <Card>
            <VStack spacing={4} align="stretch" height="100%">
                <HStack>
                    <Avatar name={name} />

                    <Text fontWeight="bold" fontSize={['2xl', 'xl', null, '2xl']}>
                        {name}
                    </Text>

                    <Spacer />
                </HStack>

                <VStack paddingX={3} paddingTop={2} divider={<StackDivider />}>
                    <KeyValue
                        label={t('Bill')}
                        targetAmount={billByUserTarget?.[id].value}
                        referenceAmount={billByUserReference?.[id].value}
                    />

                    <KeyValue
                        label={t('Tax')}
                        targetAmount={billByUserTarget?.[id].tax}
                        referenceAmount={billByUserReference?.[id].tax}
                    />

                    <KeyValue
                        label={t('Tips')}
                        targetAmount={billByUserTarget?.[id].tips}
                        referenceAmount={billByUserReference?.[id].tips}
                    />

                    <KeyValue
                        label={t('Discount')}
                        targetAmount={billByUserTarget?.[id].discount}
                        referenceAmount={billByUserReference?.[id].discount}
                    />

                    <KeyValue
                        label={t('userDetails.delivery')}
                        targetAmount={billByUserTarget?.[id].delivery}
                        referenceAmount={billByUserReference?.[id].delivery}
                    />
                </VStack>

                <Box
                    paddingX={3}
                    paddingBottom={2}
                    paddingTop={5}
                    alignSelf="flex-end"
                    display="flex"
                    width={'100%'}
                    alignItems="flex-end"
                >
                    <HStack justifyContent="space-between" width={'100%'}>
                        <Text fontSize={['xl', 'md', null, 'xl']} color="GrayText">
                            {t('Total')}
                        </Text>

                        <Box display="flex" flexDirection="column" alignItems="flex-end">
                            <Text fontSize={['3xl', '2xl', null, '3xl']} fontWeight="bold" lineHeight="1.1">
                                {billByUserTarget?.[id].total.toFixed(2)} ₽
                            </Text>

                            <Text fontSize={['xl', 'md', null, 'xl']} fontWeight="bold" color="grey">
                                {billByUserReference?.[id].total.toFixed(2)} ֏
                            </Text>
                        </Box>
                    </HStack>
                </Box>
            </VStack>
        </Card>
    );
};

export default UserDetails;

import {atom} from 'recoil';
import {persistAppStorageAtomEffect} from '../effects';
import {User} from '../types';

export const defaultSelectedParticipants = [];

export const currentSelectedParticipants = atom<User[]>({
    key: 'CurrentSelectedParticipants',
    default: defaultSelectedParticipants,
    effects: [persistAppStorageAtomEffect('currentSelectedParticipants')],
});

import React from 'react';
import {Stack, IconButton} from '@chakra-ui/react';
import {DeleteIcon} from '@chakra-ui/icons';
import {useReset} from '../../hooks';
import {ShareAction} from './ShareAction';
import {SaveAction} from './SaveAction';

const GlobalActions: React.FC = () => {
    const reset = useReset();

    return (
        <Stack position="fixed" right="4" bottom="4">
            <SaveAction />

            <ShareAction />

            <IconButton colorScheme="purple" aria-label="reset" icon={<DeleteIcon />} onClick={reset} />
        </Stack>
    );
};

export default GlobalActions;

import React from 'react';
import {useTranslation} from 'react-i18next';
import {Box, Heading, VStack} from '@chakra-ui/react';

import {Card} from '../Card';
import {useRecoilValue} from 'recoil';
import {currentRateCalculationType} from '../../state';
import {CurrencyRateSwitcher} from '../CurrencyRateSwitcher';
import {CurrencyRateFromAmountContent} from './CurrencyRateFromAmountContent';
import {CurrencyRateManualContent} from './CurrencyRateManualContent';

const CurrencyRate: React.FC = () => {
    const calculationRateType = useRecoilValue(currentRateCalculationType);
    const [t] = useTranslation('common');

    return (
        <Box display="flex" flexDir="column" flexGrow={1}>
            <Heading marginBottom={5}>{t('Rate')}</Heading>

            <Card flexGrow={1}>
                <VStack spacing={4} align="stretch">
                    <CurrencyRateSwitcher />

                    {calculationRateType === 'fromAmount' && <CurrencyRateFromAmountContent />}

                    {calculationRateType === 'manual' && <CurrencyRateManualContent />}
                </VStack>
            </Card>
        </Box>
    );
};

export default CurrencyRate;

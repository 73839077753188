import {APIUser} from '../../types';
import {fetchWithToken} from '../helpers';

export const resolveUserContacts = async (): Promise<APIUser[] | null> => {
    const response = await fetchWithToken(`/user/contacts`);

    if (response.ok) {
        return await response.json();
    }

    return null;
};

import {selector} from 'recoil';
import {currentManualExchangeRate, currentRateCalculationType, currentTargetCurrencyAmount} from '../state';
import {calcExchangeRate} from '../utils';
import {currentReferenceCurrencyAmountTotal} from './referenceCurrency';

/**
 * Получение значения цену одного драма в рублях.
 */
export const currentExchangeRate = selector({
    key: 'CurrentExchangeRate',
    get: ({get}) => {
        const rateCalculationType = get(currentRateCalculationType);

        if (rateCalculationType === 'fromAmount') {
            const targetCurrencyAmount = get(currentTargetCurrencyAmount) ?? 0;
            const referenceCurrencyAmount = get(currentReferenceCurrencyAmountTotal);

            return calcExchangeRate({targetCurrencyAmount, referenceCurrencyAmount});
        } else {
            const manualExchangeRate = get(currentManualExchangeRate);

            if (manualExchangeRate === null || manualExchangeRate === 0) {
                return 0;
            }

            return 1 / manualExchangeRate;
        }
    },
});

interface GetExchangeRateRubAmd {
    targetCurrencyAmount: number;
    referenceCurrencyAmount: number;
}

export const calcExchangeRate = ({targetCurrencyAmount, referenceCurrencyAmount}: GetExchangeRateRubAmd): number => {
    if (targetCurrencyAmount === 0 || referenceCurrencyAmount === 0) {
        return 0;
    }

    return targetCurrencyAmount / referenceCurrencyAmount;
};

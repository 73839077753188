import React from 'react';
import {useTranslation} from 'react-i18next';
import {useRecoilValue, useRecoilState} from 'recoil';
import {IconButton, Input, InputGroup, Grid, Flex, Box} from '@chakra-ui/react';
import {CopyIcon, DeleteIcon} from '@chakra-ui/icons';
import {MultiValue, Select} from 'chakra-react-select';

import {CurrencyInput} from '../CurrencyInput';
import {createOrderItem} from '../../entities';
import {currentOrderItems} from '../../state/orderItems';
import {IDetalizationItem} from '../../types/detalization';
import {OrderItem} from '../../types/orderItem';
import {activeUsers} from '../../selectors';

type IDetalizationListItemProps = IDetalizationItem;

const DetalizationListItem: React.FC<IDetalizationListItemProps> = props => {
    const [t] = useTranslation('common');
    const {id, name, amount} = props;
    const users = useRecoilValue(activeUsers);
    const [orderItems, setOrderItems] = useRecoilState(currentOrderItems);
    const currentOrderItemIdx = orderItems.findIndex(item => item.id === id);
    const currentOrderItem = orderItems[currentOrderItemIdx];

    const duplicateOrderItem = (): void => {
        const {id, ...duplicateOrderItem} = currentOrderItem;
        const items = orderItems.concat();

        items.splice(currentOrderItemIdx + 1, 0, createOrderItem(duplicateOrderItem));

        setOrderItems(items);
    };

    const removeOrderItem = (): void => {
        const items = orderItems.filter(item => item.id !== id);

        setOrderItems(items);
    };

    const changeOrderItem = (orderItemWithUsers: Partial<OrderItem>): void => {
        const newOrderItems = [...orderItems];

        newOrderItems[currentOrderItemIdx] = {
            ...currentOrderItem,
            ...orderItemWithUsers,
        };

        setOrderItems(newOrderItems);
    };

    const onChangeUserSelect = (values: MultiValue<{label: string; value: string}>): void => {
        const isSelectAll = values.some(value => value.value === 'all');

        if (isSelectAll) {
            changeOrderItem({users: users.map(user => user.id)});
        } else {
            changeOrderItem({users: values.map(value => value.value)});
        }
    };

    let selectOptions = users.map(user => ({label: user.name, value: user.id}));

    const selectValue = users
        .filter(user => currentOrderItem.users.includes(user.id))
        .map(user => ({label: user.name, value: user.id}));

    if (selectValue.length !== selectOptions.length) {
        selectOptions = [{label: t('detalization.userSelect.selectAll'), value: 'all'}].concat(selectOptions);
    }

    return (
        <Grid gap="3" gridTemplateColumns={[null, null, null, 'repeat(3, 1fr) auto']}>
            <Box gridRow={[1]} gridColumn={[1, '1 / 3', null, '1 / 2']}>
                <Select
                    isMulti
                    onChange={onChangeUserSelect}
                    name="users"
                    placeholder={t('Select users')}
                    options={selectOptions}
                    value={selectValue}
                />
            </Box>

            <Box gridRow={[2, null, null, 1]}>
                <InputGroup>
                    <Input
                        name="description"
                        onChange={event => changeOrderItem({description: event.currentTarget.value})}
                        value={name}
                        placeholder={t('Enter description')}
                        autoComplete="off"
                    />
                </InputGroup>
            </Box>

            <Box gridRow={[3, 2, null, 1]}>
                <CurrencyInput
                    name="value"
                    onChange={event =>
                        changeOrderItem({
                            value: event.currentTarget.value === '' ? undefined : Number(event.currentTarget.value),
                        })
                    }
                    value={amount}
                    currency="AMD"
                    placeholder={t('Enter price')}
                />
            </Box>

            <Flex gap="1" gridRow={[4, 3, null, 1]} gridColumn={[1, '1 / 3', null, 'auto']}>
                <IconButton
                    onClick={() => duplicateOrderItem()}
                    aria-label="duplicate item"
                    icon={<CopyIcon />}
                    colorScheme="gray"
                    size="md"
                    w={['50%']}
                />

                <IconButton
                    onClick={() => removeOrderItem()}
                    aria-label="remove"
                    icon={<DeleteIcon />}
                    colorScheme="red"
                    size="md"
                    w={['50%']}
                />
            </Flex>
        </Grid>
    );
};

export default DetalizationListItem;

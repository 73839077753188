import {selector} from 'recoil';
import {currentReferenceCurrencyAmountTotal} from './currentReferenceCurrencyAmountTotal';
import {currentReferenceCurrencyDiscount} from './currentReferenceCurrencyDiscount';
import {currentReferenceCurrencyTips} from './currentReferenceCurrencyTips';
import {currentReferenceCurrencyTax} from './currentReferenceCurrencyTax';
import {currentReferenceCurrencyDelivery} from './currentReferenceCurrencyDelivery';

/**
 * [Reference Currency]
 * Получение значения общего счета с включенными налогами.
 */
export const currentReferenceCurrencyBillInfo = selector({
    key: 'CurrentReferenceCurrencyBillInfo',
    get: ({get}) => {
        const amountTotal = get(currentReferenceCurrencyAmountTotal);
        const tipsAmount = get(currentReferenceCurrencyTips);
        const discountAmount = get(currentReferenceCurrencyDiscount);
        const deliveryAmount = get(currentReferenceCurrencyDelivery);
        const taxAmount = get(currentReferenceCurrencyTax);

        return {
            tips: tipsAmount ?? 0,
            discount: discountAmount,
            tax: taxAmount,
            delivery: deliveryAmount,
            total: amountTotal,
        };
    },
});

import {DeleteIcon} from '@chakra-ui/icons';
import {
    Box,
    AvatarGroup,
    Avatar,
    Editable,
    EditableInput,
    EditablePreview,
    Heading,
    HStack,
    List,
    ListItem,
    StyleProps,
    Text,
    IconButton,
    Icon,
} from '@chakra-ui/react';
import {RiBillLine} from 'react-icons/ri';
import React, {FC, FocusEventHandler, ReactEventHandler, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {deleteOrderById, updateOrderById} from '../../../../resolvers';
import {FullDataOrder} from '../../../../utils/calcFullDataFromOrder';
import * as styles from './styles';
import {useNavigate} from 'react-router-dom';
import {getFormatDate} from '../../../../utils';

interface Props {
    style?: StyleProps;
    orders: FullDataOrder[];
}

export const OrdersList: FC<Props> = ({style, orders}) => {
    const [t] = useTranslation('common');
    const navigate = useNavigate();
    const [localOrders, setLocalOrders] = useState(orders);

    const onClickListItem = (id: string) => () => {
        navigate(`/?share=${id}`);
    };

    const onUpdateName =
        (id: string): FocusEventHandler<HTMLInputElement> =>
        event => {
            updateOrderById({id, name: event.target.value}).catch(console.error);
        };

    const onDeleteOrder =
        (id: string): ReactEventHandler =>
        event => {
            event.stopPropagation();

            deleteOrderById(id)
                .then(() => {
                    const newOrderList = localOrders.filter(order => order.id !== id);
                    setLocalOrders(newOrderList);
                })
                .catch(console.error);
        };

    const onClickName: ReactEventHandler = event => {
        event.stopPropagation();
    };

    return (
        <Box {...style}>
            <Heading as="h2">{t('ordersPage.title')}</Heading>

            <List {...styles.orderList}>
                {localOrders.map(order => (
                    <ListItem
                        marginX="-24px"
                        paddingX="24px"
                        key={order.id}
                        {...styles.listItem}
                        _hover={{bgColor: 'gray.50'}}
                        onClick={onClickListItem(order.id)}
                    >
                        <HStack {...styles.content}>
                            <Box display="flex" alignItems="center">
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    borderRadius="50%"
                                    boxSize="55px"
                                    bgColor="gray.200"
                                    flexShrink={0}
                                >
                                    <Icon as={RiBillLine} boxSize="25px" color="gray.500" />
                                </Box>

                                <Box marginLeft="10px">
                                    <Text {...styles.id}>{getFormatDate(order.createdAt)}</Text>

                                    <Editable
                                        defaultValue={order.name ?? t('createOrder.defaultName')}
                                        // $TSFixMe обновить типизацию
                                        {...styles.name}
                                        onBlur={onUpdateName(order.id)}
                                        onClick={onClickName}
                                    >
                                        <EditablePreview />

                                        <EditableInput />
                                    </Editable>

                                    <AvatarGroup size="xs">
                                        {order.users.map(user => (
                                            <Avatar key={user.id} name={user.name} />
                                        ))}
                                    </AvatarGroup>
                                </Box>
                            </Box>

                            <Box display="flex" alignItems="center">
                                <Text {...styles.amount}>{order.targetCurrencyAmount} ₽</Text>

                                <IconButton
                                    marginLeft="10px"
                                    variant={'ghost'}
                                    colorScheme={'red'}
                                    aria-label="Delete order"
                                    icon={<DeleteIcon />}
                                    onClick={onDeleteOrder(order.id)}
                                />
                            </Box>
                        </HStack>
                    </ListItem>
                ))}
            </List>
        </Box>
    );
};

import {selector} from 'recoil';
import {activeUsersIdWithOrders} from '../activeUsersIdWithOrder';
import {currentReferenceCurrencyDelivery} from './currentReferenceCurrencyDelivery';

/**
 * [Reference Currency]
 * Получение значения доставки на пользователя.
 */
export const currentReferenceCurrencyDeliveryPerUser = selector({
    key: 'CurrentReferenceCurrencyDeliveryPerUser',
    get: ({get}) => {
        const delivery = get(currentReferenceCurrencyDelivery);
        const users = get(activeUsersIdWithOrders);

        if (users.length === 0) {
            return 0;
        }

        return delivery / users.length;
    },
});

import {selector} from 'recoil';
import {destributedOrderItems} from './currentOrderItems';

/**
 * Получения списка активных пользователей которые делали заказы.
 */
export const activeUsersIdWithOrders = selector({
    key: 'ActiveUsersIdWithOrders',
    get: ({get}) => {
        const orderItems = get(destributedOrderItems);

        const orderItemsById: Record<string, boolean> = {};

        orderItems.forEach(({users}) => {
            users.forEach(userId => {
                if (!orderItemsById[userId]) {
                    orderItemsById[userId] = true;
                }
            });
        });

        return Object.keys(orderItemsById);
    },
});

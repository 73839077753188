import React from 'react';
import {Box, Avatar, Text} from '@chakra-ui/react';
import {CheckIcon} from '@chakra-ui/icons';
import {User} from '../../types';

interface Props {
    user: User;
    isChecked?: boolean;
}

const UserChecked: React.FC<Props> = ({user, isChecked = false}) => {
    return (
        <Box
            display="flex"
            alignItems="center"
            gap="3"
            cursor="pointer"
            p="3"
            borderRadius="md"
            borderColor="gray.100"
            borderWidth="1px"
            minW="300"
            w="full"
            _hover={{backgroundColor: 'gray.100'}}
        >
            <Avatar name={user.name} src={user.avatarUrl} />

            <Box>
                <Text color="black" fontWeight="bold" fontSize="md">
                    {user.name}
                </Text>

                <Text color="gray" fontSize="sm">
                    {user.email}
                </Text>
            </Box>

            {!isChecked && (
                <Box w="28px" h="28px" ml="auto" borderWidth="1px" borderColor={'gray.300'} borderRadius="50%" p="2" />
            )}

            {isChecked && (
                <CheckIcon
                    w="3"
                    h="3"
                    ml="auto"
                    backgroundColor="green.400"
                    borderRadius="50%"
                    color="white"
                    boxSizing="content-box"
                    p="2"
                />
            )}
        </Box>
    );
};

export default UserChecked;

import {atom} from 'recoil';
import {persistAppStorageAtomEffect} from '../effects';

/**
 * Вручную введенный курс обмена.
 */
export const currentManualExchangeRate = atom<number | null>({
    key: 'ManualExchangeRate',
    default: null,
    effects: [persistAppStorageAtomEffect('currentManualExchangeRate')],
});

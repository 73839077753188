import React, {FC} from 'react';
import {useRecoilValue} from 'recoil';
import {currentOrdersQuery} from '../../queries/currentOrdersQuery';
import {calcFullDataFromOrder} from '../../utils/calcFullDataFromOrder';
import {Page} from '../Page';
import {EmptyOrdersListContent} from './components/EmptyOrdersListContent';
import {OrdersList} from './components/OrdersList';

interface Props {
    className?: string;
}

export const OrdersPage: FC<Props> = ({className}) => {
    const orders = useRecoilValue(currentOrdersQuery);

    const fullOrders = orders.map(calcFullDataFromOrder);

    return (
        <Page>
            {orders.length <= 0 && <EmptyOrdersListContent />}

            {orders.length > 0 && <OrdersList orders={fullOrders} />}
        </Page>
    );
};

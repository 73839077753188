import React, {useEffect, useState} from 'react';
import {useRecoilState} from 'recoil';
import {Stack, VStack, Box} from '@chakra-ui/react';
import i18next from 'i18next';
import {initReactI18next} from 'react-i18next';

import {CurrencyRate} from './components/CurrencyRate';
import {Detalization} from './components/Detalization';
import {GlobalActions} from './components/GlobalActions';
import {Header} from './components/Header';
import {Summary} from './components/Summary';
import {TaxAndTips} from './components/TaxAndTips';
import {UsersDetails} from './components/UsersDetails';
import {AddUser} from './components/AddUser';
import {Footer} from './components/Footer';
import {Order, OrderPageData, User} from './types';
import {useGetAppData, useSetAppData} from './hooks';
import {currentSelectedParticipants} from './state';
import commonEn from './translations/en/common.json';
import commonRu from './translations/ru/common.json';
import {ScreenLoader} from './components/ScreenLoader';
import {mapAPIUserToUser, mapToOrder} from './entities';
import {resolveOrderById, resolveUser, resolveUserContacts} from './resolvers';
import {UserEditor} from './components/UserEditor';
import {getDefaultOrderName} from './utils';

i18next
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                common: commonEn,
            },
            ru: {
                common: commonRu,
            },
        },
        lng: 'ru', // if you're using a language detector, do not define the lng option
        fallbackLng: 'en',
    })
    .catch(() => {});

export const App = (): JSX.Element => {
    const setAppData = useSetAppData();
    const getAppData = useGetAppData();
    const [isLoad, setIsLoad] = useState(false);
    const [users] = useRecoilState(currentSelectedParticipants);

    useEffect(() => {
        const getState = async (): Promise<Partial<OrderPageData>> => {
            const currentUrl = window.location.href;
            const url = new URL(currentUrl);

            const orderId = url.searchParams.get('share');

            let order: Partial<Order> | null = null;

            const user = await resolveUser();

            if (orderId !== null) {
                const response = await resolveOrderById(orderId);

                if (response !== null) {
                    order = mapToOrder(response);
                }
            }

            if (order === null) {
                order = await getAppData();
            }

            if (order.name == null) {
                order.name = getDefaultOrderName();
            }

            let contacts: User[] = [];

            if (user != null) {
                const constactsFromAPI = await resolveUserContacts();

                if (constactsFromAPI === null) {
                    return order;
                }

                contacts = constactsFromAPI.map(mapAPIUserToUser);

                return {
                    ...order,
                    contacts,
                };
            } else {
                return order;
            }
        };

        Promise.all([
            getState(),
            new Promise(resolve => {
                setTimeout(resolve, 500);
            }),
        ])
            .then(result => {
                const [appData] = result;
                setIsLoad(true);

                setAppData(appData);
            })
            .catch(console.error);
    }, []);

    return (
        <Box>
            {isLoad && (
                <>
                    <Header />

                    <UserEditor />

                    <Box p="4">
                        {users.length > 0 ? (
                            <>
                                <VStack spacing={8} align="stretch">
                                    <Summary />

                                    <UsersDetails />

                                    <Stack direction={['column', null, 'row']} spacing={3} align="stretch">
                                        <CurrencyRate />

                                        <TaxAndTips />
                                    </Stack>

                                    <Detalization />
                                </VStack>

                                <GlobalActions />
                            </>
                        ) : (
                            <AddUser />
                        )}
                    </Box>

                    <Footer />
                </>
            )}

            {!isLoad && <ScreenLoader />}
        </Box>
    );
};

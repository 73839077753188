import React from 'react';
import {Box, Spinner, Stack} from '@chakra-ui/react';
import {Logo} from '../Logo';

export const ScreenLoader: React.FC = () => (
    <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Stack display="flex" flexDir="column" alignItems="center">
            <Box marginBottom="20px">
                <Logo size={90} />
            </Box>

            <Spinner color="#9a70ed" size="lg" />
        </Stack>
    </Box>
);

import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Menu, MenuButton, MenuItem, MenuList, IconButton} from '@chakra-ui/react';
import {RussiaIcon, EnglishIcon} from '../icons';

const langIcons = {
    en: EnglishIcon,
    ru: RussiaIcon,
};

const LanguageSwitcher: React.FC = () => {
    const [t, i18n] = useTranslation('common');
    const [lang, setLang] = useState<'en' | 'ru'>('ru');
    const changeLang = useCallback(async (lang: 'en' | 'ru') => {
        await i18n.changeLanguage(lang);
        setLang(lang);
    }, []);
    const LangIcon = langIcons[lang];
    return (
        <Menu>
            {({onClose}) => (
                <>
                    <MenuButton
                        as={IconButton}
                        icon={<LangIcon fontSize="3xl" />}
                        variant="unstyled"
                        overflow="hidden"
                    />
                    <MenuList>
                        <MenuItem
                            onClick={() => {
                                changeLang('ru').catch(() => {});
                                onClose();
                            }}
                        >
                            {t('Russian')}
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                changeLang('en').catch(() => {});
                                onClose();
                            }}
                        >
                            {t('English')}
                        </MenuItem>
                    </MenuList>
                </>
            )}
        </Menu>
    );
};

export default LanguageSwitcher;

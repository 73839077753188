import {selector} from 'recoil';
import {currentTipsInfo} from '../../state';
import {currentReferenceCurrencyAmountWithTax} from './currentReferenceCurrencyAmountWithTax';

/**
 * [Reference Currency]
 * Общее значение чавых.
 */
export const currentReferenceCurrencyTips = selector({
    key: 'CurrentReferenceCurrencyTips',
    get: ({get}) => {
        const tipsInfo = get(currentTipsInfo);

        const amdAmount = get(currentReferenceCurrencyAmountWithTax);

        if (tipsInfo === null) {
            return 0;
        }

        const tipsValue = tipsInfo.value ?? 0;
        const tipsType = tipsInfo.type;

        if (tipsType === 'percent') {
            return (amdAmount * tipsValue) / 100;
        }

        return tipsValue;
    },
});

import {Input, Text} from '@chakra-ui/react';
import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useRecoilState} from 'recoil';
import {currentManualExchangeRate} from '../../state';

export const CurrencyRateManualContent = (): JSX.Element => {
    const [t] = useTranslation('common');
    const [manualExchangeRate, setManualExchangeRate] = useRecoilState(currentManualExchangeRate);

    const onChangeExchangeRate = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const amount = value === '' ? null : Number(value);

        setManualExchangeRate(amount);
    }, []);

    const inputValue = manualExchangeRate === null ? '' : manualExchangeRate;
    const exchangeText =
        manualExchangeRate !== null && manualExchangeRate > 0
            ? `1 ₽ = ${manualExchangeRate.toFixed(2)} ֏`
            : t('Exchange rate has not been set yet');
    return (
        <>
            <Text fontWeight="bold">{t('Specify exchange rate AMD/RUB')}</Text>

            <Input
                type="number"
                autoComplete="off"
                value={inputValue ?? ''}
                placeholder={t('Enter the exchange rate AMD/RUB')}
                onChange={onChangeExchangeRate}
            />

            <Text fontWeight="bold" fontSize="2xl">
                {exchangeText}
            </Text>
        </>
    );
};

import {APIUser} from '../../types';
import {JSON_HEADERS} from '../common';
import {fetchWithToken} from '../helpers';

export const createUserContacts = async (users: APIUser[]): Promise<APIUser[] | null> => {
    const response = await fetchWithToken(`/user/contacts`, {
        method: 'post',
        headers: JSON_HEADERS,
        /**
         * @todo [MoonW1nd]: add maping to users
         */
        body: JSON.stringify(users),
    });

    if (response.ok) {
        return await response.json();
    }

    return null;
};

import {APIUser} from '../../types';
import {fetchWithToken} from '../helpers';

export const resolveUser = async (): Promise<APIUser | null> => {
    const response = await fetchWithToken(`/user`);

    if (response.ok) {
        return await response.json();
    }

    return null;
};

import {selector} from 'recoil';
import {mapToOrder} from '../entities';
import {Order} from '../types';
import {resolveOrders} from '../resolvers';

/**
 * Получения списка созданных чеков.
 */
export const currentOrdersQuery = selector<Order[]>({
    key: 'currentOrdersQuery',
    get: async () => {
        const ordersData = await resolveOrders();

        if (ordersData != null) {
            return ordersData.map(mapToOrder);
        }

        return [];
    },
});

import {selector} from 'recoil';
import {
    currentDeliveryInfo,
    currentDiscountInfo,
    currentManualExchangeRate,
    currentOrderId,
    currentOrderItems,
    currentOrderName,
    currentRateCalculationType,
    currentTargetCurrencyAmount,
    currentTaxInfo,
    currentTipsInfo,
    currentSelectedParticipants,
} from '../state';
import {Order} from '../types';

/**
 * Получение всей важной информации приложения.
 */
export const currentAppData = selector({
    key: 'CurrentAppData',
    get: ({get}): Order => {
        const id = get(currentOrderId);
        const name = get(currentOrderName);
        const users = get(currentSelectedParticipants);
        const discountInfo = get(currentDiscountInfo);
        const taxInfo = get(currentTaxInfo);
        const tipsInfo = get(currentTipsInfo);
        const deliveryInfo = get(currentDeliveryInfo);
        const targetCurrencyAmount = get(currentTargetCurrencyAmount);
        const orderItems = get(currentOrderItems);
        const manualExchangeRate = get(currentManualExchangeRate);
        const rateCalculationType = get(currentRateCalculationType);

        return {
            id,
            name,
            users,
            discountInfo,
            deliveryInfo,
            taxInfo,
            tipsInfo,
            targetCurrencyAmount,
            orderItems,
            manualExchangeRate,
            rateCalculationType,
        };
    },
});

import {Order} from '../../types';
import {JSON_HEADERS} from '../common';
import {fetchWithToken, prepareOrderRequestData} from '../helpers';

export const createOrder = async (order: Order): Promise<Order | null> => {
    const response = await fetchWithToken(`/orders`, {
        method: 'post',
        headers: JSON_HEADERS,
        body: JSON.stringify(prepareOrderRequestData(order)),
    });

    if (response.ok) {
        return await response.json();
    }

    return null;
};

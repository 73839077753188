import {selector} from 'recoil';
import {mapAPIUserToUser} from '../entities';
import {resolveUser} from '../resolvers';

/**
 * Получения списка активных пользователей.
 */
export const currentLoggedUserQuery = selector({
    key: 'currentLoggedUserQuery',
    get: async () => {
        const currentUser = await resolveUser();

        if (currentUser === null) {
            return null;
        }

        return mapAPIUserToUser(currentUser);
    },
});
